import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import { useAlert } from "react-alert"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useParams, withRouter } from "react-router-dom"
import { Col, Container, Row, Spinner } from "reactstrap"
import { isEmpty, isNil } from "lodash"

import MandateDetailSummaryView from "./views/summary/MandateDetailSummaryView"
import MandateDetailSummaryViewEdit from "./views/summary/MandateDetailSummaryViewEdit"
import MandateDetailContactView from "./views/contact/MandateDetailContactView"
import MandateDetailContactViewEdit from "./views/contact/MandateDetailContactViewEdit"
import MandateDetailEvaluationObjectView from "./views/evaluationobject/MandateDetailEvaluationObjectView"
import MandateDetailTaskView from "./views/task/MandateDetailTaskView"
import ContactSearchView from "components/ContactSearch/ContactSearchView"
import Row404 from "components/Page404/Row404"

import { useCurrentUser } from "contexts/current-user-context"
import { URL_REQUESTS } from "helpers/lea-url_helper"
import { archiveMandate } from "services/MandataService"
import { archiveTask } from "services/TaskService"

import { callAPI, callAPI2, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { createMandateContact, createTask, updateMandate } from "graphql/mutations"
import { getMandateDetail, listMandateDetailSettings } from "./graphql/queries"
import { EvaluationObjectType, TaskStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function MandateDetailPage(props) {
  // Get mandate id from the current URL (query param)
  const { mandateId } = useParams()

  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [mandate, setMandate] = useState({})
  const [tasks, setTasks] = useState([])
  const [users, setUsers] = useState([])
  const [mandateContacts, setMandateContacts] = useState([])
  const [mandateEvalObjects, setMandateEvalObjects] = useState([])
  const [optionsMandateType, setOptionsMandateTypes] = useState([])
  const [optionsTaskType, setOptionsTaskType] = useState([])

  const [openEditMandateView, setOpenEditMandateView] = useState(false)
  const [openEditContactView, setOpenEditContactView] = useState(false)
  const [openEditLocationView, setOpenEditLocationView] = useState(false)
  const [openSearchContactView, setOpenSearchContactView] = useState(false)

  const [mandateContactToEdit, setMandateContactToEdit] = useState({})

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  const { isError, isIdle, isLoading, isFetching, error } = useQuery(
    "getMandateDetail-" + mandateId,
    () =>
      firstValueFrom(
        callAPI2("getMandateDetail", getMandateDetail, {
          id: mandateId,
          filterMandateContact: { mandateId: { eq: mandateId }, retired: { ne: "true" } },
          filterTask: { mandateId: { eq: mandateId } },
          limit: 1000,
        })
      ),
    {
      retry: false,
      onSuccess: result => {
        //console.log("MandateDetailPage.getMandateDetail.onSuccess:result", result)
        setMandate(isNil(result?.getMandate) ? {} : result.getMandate)
        setMandateContacts(isEmpty(result.listMandateContacts?.items) ? [] : result.listMandateContacts?.items)
        setMandateEvalObjects([{ evaluationObjectType: EvaluationObjectType.BUILDING }, { evaluationObjectType: EvaluationObjectType.LAND }, { evaluationObjectType: "OTHER" }])
        setTasks(isEmpty(result?.listTasks?.items) ? [] : result.listTasks.items)
      },
      onError: error => {
        console.error("MandateDetailPage.getMandateDetail.onError:error", error)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error }), { timeout: 0 })
      },
    }
  )

  // Get settings data
  const {
    isError: isErrorListMandateDetailSettings,
    isIdle: isIdleListMandateDetailSettings,
    isLoading: isLoadingListMandateDetailSettings,
    isFetching: isFetchingListMandateDetailSettings,
    error: errorListMandateDetailSettingss,
  } = useQuery(
    "listMandateDetailSettings-" + mandateId,
    () =>
      firstValueFrom(
        callAPI2("listMandateDetailSettings", listMandateDetailSettings, {
          /* EXAMPLE
            filterSettingsMandate: { xxx: { eq: ??? } },
            filterSettingsTask: { xxx: { eq: ??? } },
            filterUser : { userStatus: { eq: UserStatus.ACTIVE } },
            */
          limit: 1000,
        })
      ),
    {
      retry: false,
      /*enabled: !isIdle && !isLoading && !isFetching,*/ /* on n'a pas besoin d'attendre le retour de la première requête */
      onSuccess: result => {
        //console.log("MandateDetailPage.listMandateDetailSettings.onSuccess:result", result)
        setOptionsMandateTypes(isEmpty(result?.listSettingsMandateTypes?.items) ? [] : result.listSettingsMandateTypes.items)
        setOptionsTaskType(isEmpty(result?.listSettingsTaskTypes?.items) ? [] : result.listSettingsTaskTypes.items)
        // Cette liste est nécessaire pour l'assignation du responsable
        let optionsUsers = []
        if (!isEmpty(result?.listUsers?.items)) {
          result?.listUsers?.items.map(row => optionsUsers.push({ cognitoId: row.cognitoId, value: row.cognitoId, label: isEmpty(row.username) ? row.email : row.username, email: row.email, disabled: row.retired, username: row.username }))
        }
        setUsers(optionsUsers)
      },
      onError: error => {
        console.error("MandateDetailPage.listMandateDetailSettings.onError:error", error)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    }
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleMandateViewOnDelete(entityMandate) {
    //console.log("MandateDetailPage.handleMandateViewOnDelete:entityMandate", entityMandate);
    doDeleteMandate(entityMandate)
  }

  function handleMandateViewOnEdit(entityMandate) {
    //console.log("MandateDetailPage.handleMandateViewOnEdit:entityMandate", entityMandate);
    toggleEditMandateView() // Open EditMandateView
  }

  function handleEditMandateViewOnCancel() {
    //console.log("MandateDetailPage.handleEditMandateViewOnCancel");
    toggleEditMandateView() // Hide EditMandateView
  }

  function handleEditMandateViewOnSave(entityMandate) {
    //console.log("MandateDetailPage.handleEditMandateViewOnSave:entityMandate", entityMandate);
    setMandate(entityMandate)
    toggleEditMandateView() // Hide EditMandateView
  }

  function handleMandateContactViewOnAddNew() {
    //console.log("MandateDetailPage.handleMandateContactViewOnAddNew");
    toggleSearchContactView() // Open ContactSearchView
  }

  function handleMandateContactViewOnEdit(entityMandateContact) {
    //console.log("MandateDetailPage.handleMandateContactViewOnEdit:entityMandateContact", entityMandateContact);
    setMandateContactToEdit(entityMandateContact)
    toggleEditContactView() // Open EditMandateContactView
  }

  async function handleMandateContactViewOnDelete(entityMandateContact) {
    //console.log("MandateDetailPage.handleMandateContactViewOnDelete:entityMandateContactDelete", entityMandateContactDelete);
    // If the contact that remove is the primary contact on mandate
    if (entityMandateContact.primary === true) {
      mandate.primaryContact = null
      let mandateUpdate = await doUpdateMandate(mandate)
      if (isNil(mandateUpdate)) {
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de modifier les infos du contact principal du mandat" }))
        return
      }
    }
    if (!isNil(entityMandateContact)) {
      // Success : remove to view
      let newArr = mandateContacts.filter(item => {
        return item.id !== entityMandateContact.id
      })
      setMandateContacts(newArr)
    }
  }

  async function handleMandateContactViewOnSetAsPrimary(entityMandateContact) {
    //console.log("MandateDetailPage.handleMandateContactViewOnSetAsPrimary:entityMandateContact", entityMandateContact);
    doUpdatePrimaryContactOnMandate(entityMandateContact)
  }

  async function handleContactSearchViewOnSelect(entityContact) {
    //console.log("MandateDetailPage.handleContactSearchViewOnSelect:entityContact", entityContact);
    toggleSearchContactView() // Hide ContactSearchView

    // 1) Search contact in the list
    let find = mandateContacts.filter(item => item.contactId === entityContact.id)
    if (find.length !== 0) {
      customAlert.info(props.t("Page.MandateDetail.ContactView.Alert.AlreadyExists"))
      return
    }
    // 2) Create an association between mandate and contact (because relationship is N...N)
    let entityMandateContactNew = await doCreateMandateContact(entityContact)
    if (!isNil(entityMandateContactNew)) {
      // Success : add to view
      setMandateContacts(prevArray => [...prevArray, entityMandateContactNew])
    } else {
      // Error   : display a message
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible d'associer le contact au mandat" }), { timeout: 0 })
    }
  }

  async function handleContactSearchViewOnAddNew(entityContactNew) {
    //console.log("MandateDetailPage.handleContactSearchViewOnAddNew:contactData", contactData);
    toggleSearchContactView() // Hide ContactSearchView

    // Create an association between mandate and contact (because relationship is N...N)
    let entityMandateContactNew = await doCreateMandateContact(entityContactNew)
    if (!isNil(entityMandateContactNew)) {
      // Success : add to view
      setMandateContacts(prevArray => [...prevArray, entityMandateContactNew])
    } else {
      // Error   : display a message
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible d'associer le contact au mandat" }), { timeout: 0 })
    }
  }

  function handleContactSearchViewOnCancel() {
    //console.log("MandateDetailPage.handleContactSearchViewOnCancel");
    toggleSearchContactView() // Hide ContactSearchView
  }

  function handleEditMandateContactViewOnSave(entityMandateContact) {
    //console.log("MandateDetailPage.handleEditMandateContactViewOnSave:entityMandateContact", entityMandateContact);
    let newArr = mandateContacts.filter(item => {
      return item.id !== entityMandateContact.id
    })
    newArr.push(entityMandateContact)
    setMandateContacts(newArr)
    if (entityMandateContact.primary) {
      doUpdatePrimaryContactOnMandate(entityMandateContact)
    }
    toggleEditContactView() // Close EditMandateContactView
  }

  function handleEditMandateContactViewOnCancel() {
    //console.log("MandateDetailPage.handleEditMandateContactViewOnCancel");
    toggleEditContactView() // Close EditMandateContactView
  }

  function handleTaskViewOnAddNew(entitySettingsTaskType) {
    //console.log("MandateDetailPage.handleTaskViewOnAddNew:entitySettingsTaskType", entitySettingsTaskType);
    doCreateTask(mandate.requestId, mandate.id, entitySettingsTaskType)
  }

  async function doUpdatePrimaryContactOnMandate(entityMandateContact) {
    //console.log("MandateDetailPage.doUpdatePrimaryContactOnMandate:entityMandateContact", entityMandateContact);
    // Update primary contact on Mandate
    let primaryContact = {
      id: entityMandateContact.contactId,
      // contactType :
      address: isEmpty(entityMandateContact.contact?.address) ? null : entityMandateContact.contact?.address,
      city: isEmpty(entityMandateContact.contact?.city) ? null : entityMandateContact.contact?.city,
      email: isEmpty(entityMandateContact.contact?.email) ? null : entityMandateContact.contact?.email,
      fullName: isEmpty(entityMandateContact.contact?.fullName) ? null : entityMandateContact.contact?.fullName,
      phoneNumber: isEmpty(entityMandateContact.contact?.phoneNumber) ? null : entityMandateContact.contact?.phoneNumber,
      phoneExtension: isEmpty(entityMandateContact.contact?.phoneExtension) ? null : entityMandateContact.contact?.phoneExtension,
      phoneNumber2: isEmpty(entityMandateContact.contact?.phoneNumber2) ? null : entityMandateContact.contact?.phoneNumber2,
      phoneExtension2: isEmpty(entityMandateContact.contact?.phoneExtension2) ? null : entityMandateContact.contact?.phoneExtension2,
      stateOrProvince: isEmpty(entityMandateContact.contact?.stateOrProvince) ? null : entityMandateContact.contact?.stateOrProvince,
      zipOrPostalCode: isEmpty(entityMandateContact.contact?.zipOrPostalCode) ? null : entityMandateContact?.zipOrPostalCode,
    }
    mandate.primaryContact = { ...primaryContact }
    let entityMandateUpdate = await doUpdateMandate(mandate)
    if (isNil(entityMandateUpdate)) {
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de mettre les infos du contact principal" }))
      return
    }
    // Mettre à jour le state pour que le mandat soit à jour avec la dernière version de l'objet qui a été mis à jour
    setMandate(entityMandateUpdate)
  }

  async function handleTaskViewOnDelete(entityTask) {
    //console.log("MandateDetailPage.handleTaskViewOnDelete:entityTask", entityTask);
    let entityDelete = await doDeleteTask(entityTask)
    if (!isNil(entityDelete)) {
      // Success : remove to view
      let newArr = tasks.filter(item => {
        return item.id !== entityDelete.id
      })
      setTasks(newArr)
    } else {
      // Error   : display a message
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error }))
      queryClient.invalidateQueries("getMandateDetail-" + mandateId)
    }
  }

  async function doDeleteMandate(entityMandate) {
    //console.log("MandateDetailPage.doDeleteMandate:entityMandate", entityMandate);
    try {
      // Call async function to archive mandate and tasks
      await archiveMandate(entityMandate)
      // TODO (dette technique) Je dois mettre un timeout de 500ms sinon je vois le mandat dans l'écran requestDetail (et ce meme si j'invalide la query ???)
      setTimeout(() => {
        queryClient.invalidateQueries("getRequestDetail-" + mandate.requestId) // See in requestDetail
        props.history.push(URL_REQUESTS + "/" + mandate.requestId)
      }, 500)
    } catch (error) {
      console.error("MandateDetailPage.doDeleteMandate.onError:error", error)
      queryClient.invalidateQueries("getMandateDetail-" + mandateId)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error }))
    } finally {
    }
  }

  async function doCreateMandateContact(entityContact) {
    //console.log("MandateDetailPage.doCreateMandateContact:entityContact", entityContact);
    const input = {
      creator: username,
      contactId: entityContact.id,
      mandateId: mandate.id,
      contact: {
        id: entityContact.id,
        //contactType:
        fullName: entityContact.fullName,
        email: entityContact.email,
        phoneNumber: entityContact.phoneNumber,
        phoneExtension: entityContact.phoneExtension,
        phoneNumber2: entityContact.phoneNumber2,
        phoneExtension2: entityContact.phoneExtension2,
        address: entityContact.address,
        city: entityContact.city,
        stateOrProvince: entityContact.stateOrProvince,
        zipOrPostalCode: entityContact.zipOrPostalCode,
      },
      primary: false,
      retired: false,
    }
    //console.log("MandateDetailPage.doCreateMandateContact:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("MandateDetailPage.doCreateMandateContact:updateInput", updateInput)
    try {
      const result = await createMandateContactMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("MandateDetailPage.doCreateMandateContact:error", error)
      return null
    }
  }

  const createMandateContactMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createMandateContact", createMandateContact, {
        input: {
          ...data,
        },
      })
    )
  )

  const updateMandateMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateMandate", updateMandate, {
        input: {
          ...data,
        },
      })
    )
  )

  function doCreateTask(requestId, mandateId, entitySettingsTaskType) {
    //console.log("MandateDetailPage.doCreateTask", requestId, mandateId, entitySettingsTaskType);
    let input = {
      requestId: requestId,
      mandateId: mandateId,
      taskType: entitySettingsTaskType.id, // Id de la tâche
      order: entitySettingsTaskType.order,
      status: TaskStatus.PENDING,
      creator: username, // (UUID) de la session de l'utilisateur en cours
      retired: false,
    }
    createTaskMutation.mutate(input, {
      onSuccess: result => {
        //console.log("MandateDetailPage.doCreateTask.createTaskMutation.onSuccess:result", result)
        setTasks(prevArray => [...prevArray, result])
      },
      onError: error => {
        console.error("MandateDetailPage.doCreateTask.createTaskMutation.onError:error", error)
        queryClient.invalidateQueries("getMandateDetail-" + mandateId)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const createTaskMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createTask", createTask, {
        input: {
          ...data,
        },
      })
    )
  )

  async function doDeleteTask(entityTask) {
    //console.log("MandateDetailPage.doDeleteTask:entityTask", entityTask);
    try {
      // Call async function to archive task
      await archiveTask(entityTask)
      return entityTask
    } catch (error) {
      console.error("MandateDetailPage.doDeleteTask:error", error)
      return null
    } finally {
    }
  }

  async function doUpdateMandate(entityMandate) {
    //console.log("MandateDetailPage.doUpdateMandate:entityMandate", entityMandate);
    const input = {
      ...entityMandate,
    }
    //console.log("MandateDetailPage.doUpdateMandate:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("MandateDetailPage.doUpdateMandate:updateInput", updateInput)
    try {
      const result = await updateMandateMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("MandateDetailPage.doUpdateMandate:error", error)
      return null
    }
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleEditMandateView = useCallback(() => {
    setOpenEditMandateView(!openEditMandateView)
  }, [openEditMandateView])

  const toggleEditContactView = useCallback(() => {
    setOpenEditContactView(!openEditContactView)
  }, [openEditContactView])

  const toggleSearchContactView = useCallback(() => {
    setOpenSearchContactView(!openSearchContactView)
  }, [openSearchContactView])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Mandate detail</title>
      </MetaTags>
      <Container fluid={true}>
        {(isLoading || isFetching || isLoadingListMandateDetailSettings || isFetchingListMandateDetailSettings) && <Spinner className="ms-2" color="primary" />}
        {!isLoading && !isFetching && !isError && !isLoadingListMandateDetailSettings && !isFetchingListMandateDetailSettings && !isErrorListMandateDetailSettings && (
          <React.Fragment>
            {!isEmpty(mandate) /*&& mandate.retired === false*/ ? (
              <Row>
                {!openEditMandateView && !openSearchContactView && !openEditContactView && !openEditLocationView && (
                  <React.Fragment>
                    <Col xl={6}>
                      <MandateDetailSummaryView mandate={mandate} users={users} optionsMandateType={optionsMandateType} onDelete={handleMandateViewOnDelete} onEdit={handleMandateViewOnEdit} />
                      <MandateDetailContactView username={username} mandate={mandate} contacts={mandateContacts} onAddNew={handleMandateContactViewOnAddNew} onEdit={handleMandateContactViewOnEdit} onDelete={handleMandateContactViewOnDelete} onSetAsPrimary={handleMandateContactViewOnSetAsPrimary} />
                    </Col>
                    <Col xl={6}>
                      <MandateDetailTaskView mandate={mandate} tasks={tasks} users={users} optionsTaskType={optionsTaskType} onAddNew={handleTaskViewOnAddNew} onDelete={handleTaskViewOnDelete} />
                      <MandateDetailEvaluationObjectView username={username} mandate={mandate} evalObjects={mandateEvalObjects} onAddNew={handleMandateContactViewOnAddNew} onEdit={handleMandateContactViewOnEdit} onDelete={handleMandateContactViewOnDelete} />
                    </Col>
                  </React.Fragment>
                )}
                <Col xl={12}>
                  {openEditMandateView && <MandateDetailSummaryViewEdit username={username} mandate={mandate} users={users} onCancel={handleEditMandateViewOnCancel} onSave={handleEditMandateViewOnSave} />}
                  {openEditContactView && <MandateDetailContactViewEdit username={username} mandateContact={mandateContactToEdit} onCancel={handleEditMandateContactViewOnCancel} onSave={handleEditMandateContactViewOnSave} />}
                  {openSearchContactView && <ContactSearchView onSelect={handleContactSearchViewOnSelect} onAddNew={handleContactSearchViewOnAddNew} onCancel={handleContactSearchViewOnCancel} displayCancelButton={true} />}
                </Col>
              </Row>
            ) : (
              <Row404 />
            )}
          </React.Fragment>
        )}
      </Container>
    </div>
  )
}

MandateDetailPage.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(MandateDetailPage))
