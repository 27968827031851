import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import Moment from "react-moment"
import { useAlert } from "react-alert"
import { withRouter } from "react-router-dom"
import { useQuery } from "react-query"
import { Button, CardLink, Spinner } from "reactstrap"
import { isEmpty } from "lodash"

import { dateDiffInDaysFromNowWithoutTime } from "helpers/lea-date_helper"
import { getOptionById } from "helpers/lea-options_helper"
import { taskStatusClasses } from "helpers/lea-status_helper"
import { URL_MANDATES, URL_TASKS } from "helpers/lea-url_helper"

import { callAPI } from "helpers/lea-graphql_helper"
import { getMandate, getRequest } from "graphql/queries"
import { TaskStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function DesktopTaskTableRow(props) {
  const { task, optionsTaskTypes, optionsMandateTypes } = props

  // Alert
  const customAlert = useAlert()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [mandate, setMandate] = useState({})
  const [request, setRequest] = useState({})

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Get mandate info
  const {
    isError: isErrorMandate,
    isIdle: isIdleMandate,
    isLoading: isLoadingMandate,
    isFetching: isFetchingMandate,
    error: errorMandate,
  } = useQuery("getMandate" + task.mandateId, () => firstValueFrom(callAPI("getMandate", getMandate, { id: task.mandateId })), {
    retry: false,
    onSuccess: result => {
      //console.log("DesktopTaskTableRow.getMandate.onSuccess:result", result)
      setMandate(isEmpty(result) ? {} : result)
    },
    onError: error => {
      console.error("DashboardPersonalTaskCard.getMandate.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // Get request info
  const { isError, isIdle, isLoading, isFetching, error } = useQuery("getRequest" + task.requestId, () => firstValueFrom(callAPI("getRequest", getRequest, { id: task.requestId })), {
    retry: false,
    onSuccess: result => {
      //console.log("DesktopTaskTableRow.getRequest.onSuccess:result", result)
      setRequest(isEmpty(result) ? {} : result)
    },
    onError: error => {
      console.error("DesktopTaskTableRow.getRequest.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleViewMandate(e, mandateId) {
    //console.log("DesktopTaskTableRow.handleViewMandate:mandateId", mandateId);
    e.preventDefault()
    props.history.push(URL_MANDATES + "/" + mandateId)
  }

  function handleViewTask(e, taskId) {
    //console.log("DesktopTaskTableRow.handleViewTask:taskId", taskId);
    e.preventDefault()
    props.history.push(URL_TASKS + "/" + taskId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {(isLoading || isFetching || isLoadingMandate || isFetchingMandate) &&
        <tr key={task.id}>
          <td valign="middle">
            <Spinner className="ms-2" color="primary" />
          </td>
        </tr>
      }
      {(!isLoading && !isFetching && !isError && !isLoadingMandate && !isFetchingMandate && !isErrorMandate) &&
        <tr key={task.id} /*onClick={(e) => handleViewTask(e, task.id)}*/>
          <td valign="middle">
            <Button size="sm" color="secondary" outline onClick={e => handleViewTask(e, task.id)}>
              <i className="mdi mdi-eye" />
            </Button>
          </td>
          <td valign="middle">
            <span className={"badge rounded-pill badge-soft-" + taskStatusClasses[task.status]}>{props.t("Common.Enum.TaskStatus." + (task.retired ? "RETIRED" : task.status))}</span>
          </td>
          <td valign="middle">{getOptionById(optionsTaskTypes, task.taskType).name}</td>
        </tr>
      }
    </React.Fragment>
  )
}

DesktopTaskTableRow.propTypes = {
  t: PropTypes.any,
  task: PropTypes.any,
  history: PropTypes.any,
  optionsTaskTypes: PropTypes.array,
  optionsMandateTypes: PropTypes.array,
  users: PropTypes.array,
}

export default withRouter(withTranslation()(DesktopTaskTableRow))
