import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"

import moment from "moment"
//import Moment from "react-moment"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import Select from "react-dropdown-select"
import { ContentState, EditorState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, CardFooter, CardLink, CardText, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { isEmpty } from "lodash"

import DeleteConfirmationForm from "components/DeleteConfirmationForm/DeleteConfirmationForm"
//import TextareaAutosize from '@mui/base/TextareaAutosize

import { getOptionById } from "helpers/lea-options_helper"
import { URL_MANDATES } from "helpers/lea-url_helper"
import { taskStatusClasses } from "helpers/lea-status_helper"
import { TaskStatus } from "models"

// i18n
import { withTranslation, useTranslation } from "react-i18next"

// Component
function TaskDetailSummaryView(props) {
  const { task, optionsTaskType, users, onDelete, onUpdate } = props

  // Detect language change
  const { i18n } = useTranslation()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuTask, setOpenMenuTask] = useState(false)
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)

  const [editorState, setEditorState] = useState(initEditorState(task.description))
  const [displayEditAndSavebuttons, setDisplayEditAndSavebuttons] = useState(false)
  
  const taskStatusOptions = useMemo(() => { return initTaskStatusOptions() }, [i18n.language])

  // ----------------------------------------------------------------------------------------------------------------
  // Init function (for useMemo hook)
  // ----------------------------------------------------------------------------------------------------------------
  function initTaskStatusOptions() {
    let items = [
      { value: TaskStatus.PENDING, label: props.t("Common.Enum.TaskStatus.PENDING") },
      { value: TaskStatus.STARTED, label: props.t("Common.Enum.TaskStatus.STARTED") },
      { value: TaskStatus.COMPLETED, label: props.t("Common.Enum.TaskStatus.COMPLETED") },
    ]
    return items
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleViewMandate(e, mandateId) {
    //console.log("[handleViewMandate] - params[mandateId]", mandateId)
    e.preventDefault()
    props.history.push(URL_MANDATES + "/" + mandateId)
  }

  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("[handleDeleteConfirmationFormOnSave] - params[confirmationData]", confirmationData)
    //e.preventDefault();
    toggleDeleteConfirmationForm() // Hide the form
    onDelete(task)
  }

  function initEditorState(description) {
    //console.log("[initEditorState] - params[description]", description)
    if (!isEmpty(description)) {
      const contentBlock = htmlToDraft(description)
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      return EditorState.createWithContent(contentState)
    } else {
      return EditorState.createEmpty()
    }
  }

  function handlerUpdateStatus(values) {
    //console.log("[handlerUpdateStatus] - params[values]", values)
    let selectedValue = values[0]
    
    // Change the language triggers the method
    if (task.status !== selectedValue.value) {
      //console.log("[handlerUpdateStatus] - Update status with value [" + selectedValue.value + "]")
      task.status = selectedValue.value
      onUpdate(task)
    }
  }

  function handlerUpdateAssignee(values) {
    //console.log("[handlerUpdateAssignee] - params[values]", values)
    if (isEmpty(values)) {
      // Remove the user
      if (!isEmpty(task.assignee)) {
        task.assignee = null
        onUpdate(task)
      } else {
        //console.log("[handlerUpdateAssignee] - No update, the task did not have an assigned user")
      }
    } else {
      // Assign the user
      let selectedValue = values[0]
      if (task.assignee !== selectedValue.cognitoId) {
        task.assignee = selectedValue.cognitoId
        onUpdate(task)
      } else {
        //console.log("[handlerUpdateAssignee] - No update, the user is the same")
      }
    }
  }

  function updateDescription(e) {
    //console.log("[updateDescription] - params[]")
    e.preventDefault()
    task.description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setDisplayEditAndSavebuttons(false)
    onUpdate(task)
  }

  function cancelUpdateDescription(e) {
    //console.log("[cancelUpdateDescription] - params[]")
    e.preventDefault()
    setDisplayEditAndSavebuttons(false)
    setEditorState(initEditorState(task.description)) // reinit
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuTask = useCallback(() => {
    setOpenMenuTask(!openMenuTask)
  }, [openMenuTask])

  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  function creatorDisplayName() {
    let usr = users.find(item => {
      return item.cognitoId === task.creator
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.email
    }
    return usr.username
  }

  function assigneeDisplayName() {
    let usr = users.find(item => {
      return item.cognitoId === task.assignee
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.email
    }
    return usr.username
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Form>
      <Card>
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1">
              <CardTitle>{props.t("Page.TaskDetail.SummaryView")}</CardTitle>
            </div>
            {!task.retired && (
              <Dropdown direction="left" className="ms-2" isOpen={openMenuTask} toggle={toggleMenuTask}>
                <DropdownToggle tag="a" className="text-muted">
                  <i className="mdi mdi-dots-vertical font-size-18" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={toggleDeleteConfirmationForm}>{props.t("Page.TaskDetail.SummaryView.Action.Delete")}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
          {openDeleteConfirmationForm && <DeleteConfirmationForm id={task.id} toggle={toggleDeleteConfirmationForm} onSave={handleDeleteConfirmationFormOnSave} title={props.t("Page.TaskDetail.SummaryView.Form.DeleteConfirmation.Title")} body={props.t("Page.TaskDetail.SummaryView.Form.DeleteConfirmation.Body", { 0: getOptionById(optionsTaskType, task.taskType).name })} />}
          <Row className="mt-2">
            {/* <Col xl="4" xs="12">
              <Label>
                <i className="bx bx-calendar me-1 text-primary" />
                {props.t("Common.Label.Date.CreatedAt")}
              </Label>
              <div>{isEmpty(task.createdAt) ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{task.createdAt}</Moment>}</div>
            </Col> */}
            <Col xl="4" xs="12">
              <Label>
                <i className="fas fa-tasks me-1 text-primary" />
                {props.t("Common.Label.TaskType")}
              </Label>
              <div>{getOptionById(optionsTaskType, task.taskType).name}</div>
            </Col>
            <Col xl="4" xs="12"></Col>
            <Col xl="4" xs="12"></Col>
          </Row>
          <hr/>
          <Row>
            <Col xl="4" xs="12">
              <Label>
                <i className="bx bx-user-check me-1 text-primary" />
                {props.t("Common.Label.Assignee")}
              </Label>
              <Select
                name="assignee"
                disabled={task.retired}
                placeholder={props.t("Common.Text.User.Unassigned")}
                values={/*userAssignee*/ users.filter(item => item.value === task.assignee)}
                options={users}
                onChange={values => handlerUpdateAssignee(values)}
                clearable
                /*closeOnSelect*/
                portal={typeof document !== `undefined` && document.body}
              />
            </Col>
            <Col xl="4" xs="12">
              <Label>
                <i className="bx bx-award me-1 text-primary" />
                {props.t("Common.Label.Status")}&nbsp;
                <sup>
                  <span className={"badge rounded-pill badge-soft-" + taskStatusClasses[task.status]}>
                    {task.retired && <s>{props.t("Common.Enum.TaskStatus." + task.status)}</s>}
                    {!task.retired && <>{props.t("Common.Enum.TaskStatus." + task.status)}</>}
                  </span>
                </sup>
                {task.retired && <span className={"badge rounded-pill badge-soft-" + taskStatusClasses["RETIRED"]}>{props.t("Common.Enum.TaskStatus." + (task.retired ? "RETIRED" : task.status))}</span>}
              </Label>
              <Select
                name="status"
                disabled={task.retired}
                values={taskStatusOptions.filter(item => item.value === task.status)}
                options={taskStatusOptions}
                onChange={values => handlerUpdateStatus(values)}
                /*closeOnSelect*/
                portal={typeof document !== `undefined` && document.body}
              />
            </Col>
            <Col xl="4" xs="12">
              {/* <Label>
                <i className="bx bx-user me-1 text-primary" />
                {props.t("Common.Label.Creator")}
              </Label>
              <div>{creatorDisplayName()}</div> */}
            </Col>
          </Row>
          <hr />
          <Row >
            <Col xs="12">
              <FormGroup>
                <Label>
                  <i className="bx bx-notepad me-1 text-primary" />
                  {props.t("Common.Label.TaskDescription")}
                </Label>
                <Editor
                  editorState={editorState}
                  readOnly={task.retired}
                  toolbarHidden={!displayEditAndSavebuttons}
                  //wrapperClassName="demo-wrapper"
                  //wrapperClassName="wrapper-class"
                  //editorClassName="demo-editor"
                  //editorClassName="editor-class"
                  //toolbarClassName="toolbar-class"
                  onEditorStateChange={stateChange => {
                    setEditorState(stateChange)
                    setDisplayEditAndSavebuttons(true)
                  }}
                  placeholder={props.t("Page.TaskDetail.SummaryView.Placeholder.Description")}
                  toolbar={{
                    options: ["blockType", "inline", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "remove", "history"],
                  }}
                />
              </FormGroup>
              {displayEditAndSavebuttons && (
                <div className="mt-2">
                  <Button size="sm" className="me-2" color="primary" onClick={e => updateDescription(e)}>
                    {props.t("Common.Action.Save")}
                  </Button>
                  <Button size="sm" className="me-2" outline onClick={e => cancelUpdateDescription(e)}>
                    {props.t("Common.Action.Cancel")}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="no-print">
          <div className="d-flex">
            <div className="flex-grow-1">
              <CardLink onClick={e => handleViewMandate(e, task.mandateId)}>{props.t("Common.Action.BackToMandate")}</CardLink>
            </div>
            <CardText className="text-end">
              <small className="text-muted">
                {props.t("Common.Text.Date.CreatedBy", { 0: moment(task.createdAt).format("YYYY-MM-DD"), 1: moment(task.createdAt).format("HH:mm:ss"), 2: creatorDisplayName()})}
              </small>
            </CardText>
          </div>
        </CardFooter>
      </Card>
    </Form>
  )
}

TaskDetailSummaryView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  task: PropTypes.any,
  optionsTaskType: PropTypes.any,
  users: PropTypes.any,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default withRouter(withTranslation()(TaskDetailSummaryView))
