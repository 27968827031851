import React, { useRef, useState } from "react"
import PropTypes from "prop-types"

import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import filterFactory, { dateFilter, selectFilter, textFilter } from "react-bootstrap-table2-filter"

import MetaTags from "react-meta-tags"
import Moment from "react-moment"
//import * as moment from "moment";
//import Moment  from 'moment-business-days'
import { useAlert } from "react-alert"
import { Link, withRouter } from "react-router-dom"
import { useQuery } from "react-query"
import { Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import { isEmpty, isNull, isUndefined } from "lodash"

import { useCurrentUser } from "contexts/current-user-context"
import { getOptionById } from "helpers/lea-options_helper"
import { formatPhoneNumberAsLocal } from "helpers/lea-phonenumber_helper"
import { mandateStatusClasses } from "helpers/lea-status_helper"
import { URL_MANDATES } from "helpers/lea-url_helper"

import { API, graphqlOperation } from "aws-amplify"
import { callAPI } from "helpers/lea-graphql_helper"
import { listMandates, listSettingsMandateTypes, listUsers } from "graphql/queries"
import { MandateStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Will be use to keep filter fields names
let statusFilter
let assigneeFilter
let mandateTypeFilter
let primaryContactFilter
//let lotNumberFilter
let creationDateFilter

// Component
function MandatesMasterPage(props) {
  // Alert
  const customAlert = useAlert()

  // We use React Refs to directly access the components -> BootstrapTable
  const node = useRef()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [mandates, setMandates] = useState([])
  const [optionsMandateType, setOptionsMandateTypes] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [limit, setLimit] = useState(100)
  const [nextTokenAWS, setNextTokenAWS] = useState()
  const [nextNextTokenAWS, setNextNextTokenAWS] = useState()
  const [previousTokensAWS, setPreviousTokensAWS] = useState([])
  const [hasMoreResults, setHasMoreResults] = useState(false)

  const [filterPosition, setFilterPosition] = useState("top")
  const [mandateTypeFilterOptions, setMandateTypeFilterOptions] = useState([])
  const [userFilterOptions, setUserFilterOptions] = useState([])

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Function to load mandtes when the form is finishing to refresh (see useEffect())
  const fetch = async () => {
    setIsLoading(true)
    try {
      const variables = {
        filter: { retired: { ne: "true" } }, // exclure les retired = true
        nextToken: nextTokenAWS,
        limit: limit,
      }
      Object.keys(variables).forEach(key => {
        if (isNull(variables[key]) || isUndefined(variables[key])) {
          delete variables[key]
        }
      })
      //console.log("fetch.variables=", variables)
      const result = await API.graphql(graphqlOperation(listMandates, variables))
      //console.log("useEffect:result", result)

      setNextNextTokenAWS(result.data.listMandates.nextToken)
      let listOfMandates = mandates.concat(result.data.listMandates.items)
      setMandates(listOfMandates)
      if (isUndefined(result.data.listMandates.nextToken) || isNull(result.data.listMandates.nextToken)) {
        setHasMoreResults(false)
        //console.log("useEffect:hasNext = ", false)
      } else {
        setHasMoreResults(true)
        //console.log("useEffect:hasNext = ", true)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetch()
  }, [nextTokenAWS, limit]) // execute l'effet que si le token a changé

  // Get list of mandate types
  const {
    isLoading: isLoadingSettingsMandateType,
    isFetching: isFetchingSettingsMandateType,
    isError: isErrorSettingsMandateType,
    error: errorSettingsMandateType,
  } = useQuery("listSettingsMandateTypes", () => firstValueFrom(callAPI("listSettingsMandateTypes", listSettingsMandateTypes)), {
    retry: false,
    //enabled: !isIdle && !isLoading && !isFetching,
    onSuccess: result => {
      //console.log("listSettingsMandateTypes.onSuccess:result", result)
      setOptionsMandateTypes(isEmpty(result?.items) ? [] : result.items)
      // Mapping pour pouvoir utiliser le filter de recherche
      let arrayOfMandateTypeOptions = []
      if (!isEmpty(result?.items)) {
        result?.items.map(row =>
          arrayOfMandateTypeOptions.push({
            id: row.id,
            value: row.id,
            label: row.retired ? row.name + " (archive) " : row.name,
            order: row.order,
          })
        )
      }
      let data = arrayOfMandateTypeOptions.sort((a, b) => {
        if (a.order < b.order) {
          return -1
        }
      })
      setMandateTypeFilterOptions(data)
    },
    onError: error => {
      console.error("MandatesMasterPage.listSettingsMandateTypes.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // Get list of users
  const {
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
    isError: isErrorUsers,
    error: errorUsers,
  } = useQuery("listUsers", () => firstValueFrom(callAPI("listUsers", listUsers)), {
    retry: false,
    //enabled: !isIdle && !isLoading && !isFetching,
    onSuccess: result => {
      //console.log("MandatesMasterPage.listUsers.onSuccess:result", result)
      let optionsUsers = []
      if (!isEmpty(result?.items)) {
        result?.items.map(row =>
          optionsUsers.push({
            cognitoId: row.cognitoId,
            value: row.cognitoId,
            label: isEmpty(row.username) ? row.email : row.username,
            disabled: row.retired,
            username: row.username,
          })
        )
      }
      setUserFilterOptions(optionsUsers)
    },
    onError: error => {
      console.error("MandatesMasterPage.listUsers.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleLoadMoreResult() {
    //console.log("MandatesMasterPage.handleLoadMoreResult");
    setPreviousTokensAWS(prev => [...prev, nextTokenAWS])
    setNextTokenAWS(nextNextTokenAWS)
    setNextNextTokenAWS(null)
  }

  function handleClearAllFilters() {
    //console.log("MandatesMasterPage.handleClearAllFilters");
    statusFilter("")
    mandateTypeFilter("")
    assigneeFilter("")
    primaryContactFilter("")
    //lotNumberFilter("")
    creationDateFilter("")
  }

  function handleViewMandate(e, mandateId) {
    //console.log("MandatesMasterPage.handleViewMandate:mandateId", mandateId);
    e.preventDefault()
    props.history.push(URL_MANDATES + "/" + mandateId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  function assigneeDisplayName(row) {
    let usr = userFilterOptions.find(item => {
      return item.cognitoId === row.assignee
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.label
    }
    return usr.username
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Def columns
  // ----------------------------------------------------------------------------------------------------------------
  const columns = [
    /*
    {
      dataField: "rowCount",
      isDummyField: true,
      text: "#",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleEditMandate(e, row.id)
        },
      },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex) => <div>{rowIndex + 1}</div>,
      headerStyle: (colum, colIndex) => {
        return { width: "5%", textAlign: "center" }
      },
      style: {
        cursor: "default",
        textAlign: "center",
      },
    },
    */
    {
      dataField: "status",
      isDummyField: false,
      text: props.t("Common.Label.Status"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewMandate(e, row.id)
        },
      },
      filter: selectFilter({
        className: 'no-print',
        options: [
          {
            value: MandateStatus.PENDING,
            label: props.t("Common.Enum.MandateStatus.PENDING"),
          },
          {
            value: MandateStatus.INPROGRESS,
            label: props.t("Common.Enum.MandateStatus.INPROGRESS"),
          },
          {
            value: MandateStatus.COMPLETED,
            label: props.t("Common.Enum.MandateStatus.COMPLETED"),
          },
        ],
        getFilter: filter => {
          statusFilter = filter
        },
        //withoutEmptyOption: true,  // hide the default select option
        //placeholder: 'Select Status...',
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => <span className={"badge rounded-pill badge-soft-" + mandateStatusClasses[row.status]}>{props.t("Common.Enum.MandateStatus." + row.status)}</span>,
      /*headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "createdAt",
      isDummyField: false,
      text: props.t("Common.Label.Date.CreatedAt"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewMandate(e, row.id)
        },
      },
      filter: dateFilter({
        className: 'no-print',
        //placeholder: 'Enter Client...',
        getFilter: filter => {
          creationDateFilter = filter
        },
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (isEmpty(row?.createdAt) ? "" : <Moment format="YYYY-MM-DD">{row.createdAt}</Moment>),
      /*headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "mandateTypeId",
      isDummyField: false,
      text: props.t("Common.Label.MandateType"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewMandate(e, row.id)
        },
      },
      filter: selectFilter({
        className: 'no-print',
        options: mandateTypeFilterOptions,
        getFilter: filter => {
          mandateTypeFilter = filter
        },
        //placeholder: 'Select Status...',
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => getOptionById(optionsMandateType, cell).name,
      /*headerStyle: (colum, colIndex) => {
        return { width: "15%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "primaryContact.fullName",
      isDummyField: true,
      text: props.t("Common.Label.PrimaryContact"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewMandate(e, row.id)
        },
      },
      filter: textFilter({
        //placeholder: 'Enter Client...',
        className: 'no-print',
        getFilter: filter => {
          primaryContactFilter = filter
        },
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (
        <div>
          {isEmpty(row?.primaryContact) ? (
            <div className="mb-0 text-danger">
              <i className="mdi mdi-alert-outline me-1" />
              {props.t("Common.Text.Alert.PrimaryContact")}
            </div>
          ) : (
            <React.Fragment>
              <p className="mb-0">{row.primaryContact?.fullName}</p>
              <p className="mb-0">{row.primaryContact?.email}</p>
              <p className="mb-0">{formatPhoneNumberAsLocal(row.primaryContact?.phoneNumber)}</p>
            </React.Fragment>
          )}
        </div>
      ),
      /*headerStyle: (colum, colIndex) => {
        return { width: "25%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    {
      dataField: "assignee",
      isDummyField: false,
      text: props.t("Common.Label.Assignee"),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleViewMandate(e, row.id)
        },
      },
      filter: selectFilter({
        className: 'no-print',
        options: userFilterOptions,
        getFilter: filter => {
          assigneeFilter = filter
        },
        //placeholder: 'Select Status...',
      }),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => <div>{isEmpty(row.assignee) ? row.assignee : assigneeDisplayName(row)}</div>,
      /*headerStyle: (colum, colIndex) => {
        return { width: "25%" }
      },*/
      style: {
        cursor: "default",
      },
      sort: true,
    },
    // {
    //   dataField: "estimedDeliveryDate",
    //   isDummyField: true,
    //   text: props.t("Common.Label.Date.EstimedDeliveryDate"),
    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       handleViewMandate(e, row.id)
    //     },
    //   },
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cell, row) =>
    //     isEmpty(row?.estimedDeliveryDate) ? (
    //       ""
    //     ) : (
    //       <Moment format="YYYY-MM-DD">{row.estimedDeliveryDate}</Moment>
    //     ),
    //   /*headerStyle: (colum, colIndex) => {
    //     return { width: "15%" }
    //   },*/
    //   style: {
    //     cursor: "default",
    //   },
    //   sort: true,
    // },
    // {
    //   dataField: "deliveryDate",
    //   isDummyField: true,
    //   text: props.t("Common.Label.Date.DeliveryDate"),
    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       handleViewMandate(e, row.id)
    //     },
    //   },
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cell, row) =>
    //     isEmpty(row?.deliveryDate) ? (
    //       ""
    //     ) : (
    //       <Moment format="YYYY-MM-DD">{row?.deliveryDate}</Moment>
    //     ),
    //   /*headerStyle: (colum, colIndex) => {
    //     return { width: "10%", textAlign: "left" }
    //   },*/
    //   style: {
    //     cursor: "default",
    //   },
    //   sort: true,
    // },
    /* 
    {
      dataField: "action",
      isDummyField: true,
      text: props.t("Common.Label.Action"),
      headerAlign: 'center',
      align: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <UncontrolledDropdown>
          <DropdownToggle tag="a" className="text-muted">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={(e) => handleEditMandate(e, row.id)}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
              {props.t("Common.Action.Edit")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
    */
  ]

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Mandates</title>
      </MetaTags>
      <Container fluid>
        {/* <h4>{props.t("Page.MandatesMaster")}</h4> */}
        {(isLoading || isLoadingSettingsMandateType || isFetchingSettingsMandateType || isLoadingUsers || isFetchingUsers) && <Spinner className="ms-2" color="primary" />}
        {(!isLoading && !isLoadingSettingsMandateType && !isFetchingSettingsMandateType && !isLoadingUsers && !isFetchingUsers) &&
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ToolkitProvider bootstrap4 columns={columns} data={mandates} keyField="id">
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 no-print">
                          <Col sm="12">
                            <Button color="secondary" outline onClick={handleClearAllFilters}>
                              <i className="mdi mdi-filter-remove-outline me-1" />
                              {props.t("Common.Action.ClearFilters")}
                            </Button>
                            {/* <Button color="primary" outline onClick={ (e) => console.log(node)}>
                                GetCurrentData
                              </Button> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              bordered={false}
                              condensed
                              //BootstrapTable
                              //classes={"table align-middle table-nowrap"}
                              //classes={"table align-middle responsiveTable"}
                              filter={filterFactory()}
                              filterPosition={filterPosition}
                              hover
                              headerWrapperClasses={"table-light"}
                              keyField="id"
                              ref={node}
                              {...toolkitProps.baseProps}
                              wrapperClasses="table-responsive"
                            />
                            {/* <ExportCSVButton {...toolkitProps.csvProps}>Export CSV!!</ExportCSVButton> */}
                          </Col>
                        </Row>
                        {hasMoreResults && (
                          <Row>
                            <Col xs="12">
                              <div className="text-center my-3">
                                <Link to="#" className="text-success" onClick={handleLoadMoreResult}>
                                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                  {props.t("Common.Action.LoadMore")}
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}

MandatesMasterPage.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.any,
}

export default withRouter(withTranslation()(MandatesMasterPage))
