import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"

import { useAlert } from "react-alert"
import { useQuery, useQueryClient } from "react-query"
import { withRouter } from "react-router-dom"
import { Button, Spinner } from "reactstrap"
import { isEmpty } from "lodash"

import { getOptionById } from "helpers/lea-options_helper"
import { mandateStatusClasses } from "helpers/lea-status_helper"
import { URL_MANDATES } from "helpers/lea-url_helper"

import { callAPI } from "helpers/lea-graphql_helper"
import { getMandate } from "graphql/queries"
import { firstValueFrom } from "rxjs"

//i18n
import { withTranslation } from "react-i18next"

// Component
function ContactDetailMandateTableRow(props) {
  const { mandateContact, optionsMandateTypes } = props

  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [mandate, setMandate] = useState({})

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Get list of mandates contact
  const { isError, isIdle, isLoading, isFetching, error } = useQuery("getMandate-" + mandateContact.mandateId, () => firstValueFrom(callAPI("getMandate", getMandate, { id: mandateContact.mandateId })), {
    retry: false,
    onSuccess: result => {
      //console.log("ContactDetailPage.MandateContactTableRow.getMandate.onSuccess:result", result)
      setMandate(isEmpty(result) ? {} : result)
    },
    onError: error => {
      console.error("ContactDetailPage.MandateContactTableRow.getMandate.onError:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }), { timeout: 0 })
    },
  })

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  const handleViewMandate = (e, mandateId) => {
    //console.log("MandateView.handleViewMandate:taskId", taskId);
    e.preventDefault()
    props.history.push(URL_MANDATES + "/" + mandateId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering component
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Fragment>
      {(isLoading || isFetching) &&
        <tr key={mandateContact.id}>
          <td valign="middle">
            <Spinner className="ms-2" color="primary" />
          </td>
        </tr>
      }
      {(!isLoading && !isFetching && !isError) &&
        <tr key={mandateContact.id}>
          <td className="no-print" valign="middle" width="10%">
            <Button className="me-2" size="sm" color="secondary" outline onClick={e => handleViewMandate(e, mandateContact.mandateId)}>
              <i className="mdi mdi-eye" />
            </Button>
          </td>
          <td valign="middle">
            <span className={"badge rounded-pill badge-soft-" + mandateStatusClasses[mandate.status]}>
              {mandate.retired && <s>{props.t("Common.Enum.MandateStatus." + mandate.status)}</s>}
              {!mandate.retired && <>{props.t("Common.Enum.MandateStatus." + mandate.status)}</>}
            </span>
            {mandate.retired && <span className={"badge rounded-pill badge-soft-" + mandateStatusClasses["RETIRED"]}>{props.t("Common.Enum.MandateStatus." + (mandate.retired ? "RETIRED" : mandate.status))}</span>}
          </td>
          <td valign="middle">{getOptionById(optionsMandateTypes, mandate.mandateTypeId).name}</td>
          <td className="text-end" valign="middle" style={{ fontFamily: "monospace" }}>
            {mandate.id}
          </td>
        </tr>
      }
    </Fragment>
  )
}

ContactDetailMandateTableRow.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  mandateContact: PropTypes.any,
  optionsMandateTypes: PropTypes.array,
}

export default withRouter(withTranslation()(ContactDetailMandateTableRow))
