// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ContactType = {
  "COMPANY": "COMPANY",
  "PERSON": "PERSON"
};

const EvaluationObjectType = {
  "BUILDING": "BUILDING",
  "LAND": "LAND",
  "OTHER": "OTHER"
};

const PaymentMethodType = {
  "CASH": "CASH",
  "CHECK": "CHECK",
  "CREDIT": "CREDIT",
  "DEBIT": "DEBIT",
  "EFT": "EFT",
  "WIRE": "WIRE"
};

const InvoiceStatus = {
  "DRAFT": "DRAFT",
  "PAID": "PAID",
  "SENT": "SENT"
};

const MandateGoalType = {
  "DAMAGE": "DAMAGE",
  "INSURABLEVALUE": "INSURABLEVALUE",
  "MARKETVALUE": "MARKETVALUE",
  "OTHER": "OTHER",
  "PROSPECTIVEVALUE": "PROSPECTIVEVALUE",
  "REALVALUE": "REALVALUE",
  "RENTALVALUE": "RENTALVALUE",
  "RETROSPECTIVEVALUE": "RETROSPECTIVEVALUE",
  "SERVITUDE": "SERVITUDE"
};

const MandatePriorityType = {
  "CRITICAL": "CRITICAL",
  "HIGH": "HIGH",
  "LOW": "LOW",
  "MEDIUM": "MEDIUM"
};

const MandatePurposeType = {
  "BUY": "BUY",
  "CAPITALGAIN": "CAPITALGAIN",
  "CONTESTATION": "CONTESTATION",
  "EXPROPRIATION": "EXPROPRIATION",
  "FINANCING": "FINANCING",
  "FISCAL": "FISCAL",
  "INSURANCE": "INSURANCE",
  "INTERNALMANAGEMENT": "INTERNALMANAGEMENT",
  "LITIGATION": "LITIGATION",
  "MARKETRESEARCH": "MARKETRESEARCH",
  "OTHER": "OTHER",
  "PENSIONFUND": "PENSIONFUND",
  "PREEMPTIVE": "PREEMPTIVE",
  "PROJECTPORTFOLIO": "PROJECTPORTFOLIO",
  "REDEVELOPMENT": "REDEVELOPMENT",
  "SALE": "SALE",
  "SEPARATION": "SEPARATION",
  "SUCCESSION": "SUCCESSION"
};

const MandateReportType = {
  "ABBREVIATED": "ABBREVIATED",
  "CONSULTATION": "CONSULTATION",
  "LETTRE": "LETTRE",
  "MONITORINGWORKS": "MONITORINGWORKS",
  "NARRATIVE": "NARRATIVE"
};

const MandateStatus = {
  "COMPLETED": "COMPLETED",
  "INPROGRESS": "INPROGRESS",
  "PENDING": "PENDING"
};

const ContactRole = {
  "ACCOUNTANT": "ACCOUNTANT",
  "AGENCY": "AGENCY",
  "AGENT": "AGENT",
  "BILLING": "BILLING",
  "BUYER": "BUYER",
  "CITY": "CITY",
  "CLIENT": "CLIENT",
  "DIRECTOR": "DIRECTOR",
  "FINANCIALINSTITUTION": "FINANCIALINSTITUTION",
  "GOVERNMENT": "GOVERNMENT",
  "LAWYER": "LAWYER",
  "MANAGER": "MANAGER",
  "MINISTRY": "MINISTRY",
  "NOTARY": "NOTARY",
  "OTHER": "OTHER",
  "OWNER": "OWNER",
  "PRESIDENT": "PRESIDENT",
  "REALESTATEAGENT": "REALESTATEAGENT",
  "REALTOR": "REALTOR",
  "REQUESTOR": "REQUESTOR",
  "SECRETARY": "SECRETARY",
  "SYNDICATE": "SYNDICATE",
  "VENDOR": "VENDOR",
  "VICEPRESIDENT": "VICEPRESIDENT"
};

const CommunicationChannel = {
  "EMAIL": "EMAIL",
  "PHONE": "PHONE",
  "SOCIAL_MEDIA": "SOCIAL_MEDIA",
  "WEBSITE": "WEBSITE"
};

const ReferrerType = {
  "CLIENT": "CLIENT",
  "OTHER": "OTHER",
  "SEARCH": "SEARCH",
  "SOCIAL_MEDIA": "SOCIAL_MEDIA",
  "WEBSITE": "WEBSITE"
};

const RequestStatus = {
  "COMPLETED": "COMPLETED",
  "INPROGRESS": "INPROGRESS",
  "PENDING": "PENDING"
};

const TaskStatus = {
  "COMPLETED": "COMPLETED",
  "PENDING": "PENDING",
  "STARTED": "STARTED"
};

const UserStatus = {
  "ACTIVE": "ACTIVE",
  "CANCELED": "CANCELED",
  "INVITATION_SENT": "INVITATION_SENT",
  "INVITING": "INVITING"
};

const ModelAttributeTypes = {
  "NULL": "_null",
  "BINARY": "binary",
  "BINARY_SET": "binarySet",
  "BOOL": "bool",
  "LIST": "list",
  "MAP": "map",
  "NUMBER": "number",
  "NUMBER_SET": "numberSet",
  "STRING": "string",
  "STRING_SET": "stringSet"
};

const ModelSortDirection = {
  "ASC": "ASC",
  "DESC": "DESC"
};

const { Contact, EvaluationObject, Invoice, CustomContact, CustomInvoiceLineItem, CustomImage, CustomLink, Mandate, MandateContact, Note, Request, RequestContact, SettingsCompanyInformation, SettingsMandateType, CustomTranslation, SettingsTaskType, Task, Tenant, User, ModelAddressConnection, Address, ModelContactConnection, ModelEvaluationObjectConnection, ModelInvoiceConnection, ModelMandateContactConnection, ModelMandateConnection, ModelNoteConnection, ModelRequestContactConnection, ModelRequestConnection, ModelSettingsCompanyInformationConnection, ModelSettingsMandateTypeConnection, ModelSettingsTaskTypeConnection, ModelTaskConnection, ModelTenantConnection, ModelUserConnection, CustomLocation, CustomLot, CustomZonage, CustomRate, CustomRateOverride } = initSchema(schema);

export {
  ContactType,
  EvaluationObjectType,
  PaymentMethodType,
  InvoiceStatus,
  MandateGoalType,
  MandatePriorityType,
  MandatePurposeType,
  MandateReportType,
  MandateStatus,
  ContactRole,
  CommunicationChannel,
  ReferrerType,
  RequestStatus,
  TaskStatus,
  UserStatus,
  ModelAttributeTypes,
  ModelSortDirection,
  Contact,
  EvaluationObject,
  Invoice,
  CustomContact,
  CustomInvoiceLineItem,
  CustomImage,
  CustomLink,
  Mandate,
  MandateContact,
  Note,
  Request,
  RequestContact,
  SettingsCompanyInformation,
  SettingsMandateType,
  CustomTranslation,
  SettingsTaskType,
  Task,
  Tenant,
  User,
  ModelAddressConnection,
  Address,
  ModelContactConnection,
  ModelEvaluationObjectConnection,
  ModelInvoiceConnection,
  ModelMandateContactConnection,
  ModelMandateConnection,
  ModelNoteConnection,
  ModelRequestContactConnection,
  ModelRequestConnection,
  ModelSettingsCompanyInformationConnection,
  ModelSettingsMandateTypeConnection,
  ModelSettingsTaskTypeConnection,
  ModelTaskConnection,
  ModelTenantConnection,
  ModelUserConnection,
  CustomLocation,
  CustomLot,
  CustomZonage,
  CustomRate,
  CustomRateOverride
};