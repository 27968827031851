export const getMandateDetail = /* GraphQL */ `
  query getMandateDetail($id: ID!, $filterMandateContact: ModelMandateContactFilterInput, $filterTask: ModelTaskFilterInput, $limit: Int, $nextToken: String) {
    getMandate(id: $id) {
      _deleted
      _version
      id
      createdAt
      creator
      assignee
      primaryContact {
        address
        city
        contactType
        email
        fullName
        id
        phoneNumber
        phoneExtension
        phoneNumber2
        phoneExtension2
        stateOrProvince
        zipOrPostalCode
      }
      estimedDeliveryDate
      deliveryDate
      goal
      goalReason
      mandateTypeId
      order
      paperVersion
      priority
      purpose
      purposeReason
      reportType
      requestId
      retired
      status
    },
    listMandateContacts(filter: $filterMandateContact, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        contactId
        mandateId
        roles
        contact {
          address
          city
          contactType
          email
          fullName
          id
          phoneNumber
          phoneExtension
          phoneNumber2
          phoneExtension2
          stateOrProvince
          zipOrPostalCode
        }
        primary
        createdAt
        id
        retired
        updatedAt
      }
      nextToken
      startedAt
    },
    listTasks(filter: $filterTask, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        assignee
        createdAt
        creator
        id
        mandateId
        requestId
        retired
        status
        taskType
        updatedAt
        order
      }
      nextToken
      startedAt
    }
  }
`;

export const listMandateDetailSettings = /* GraphQL */ `
  query listMandateDetailSettings($filterSettingsMandate: ModelSettingsMandateTypeFilterInput, $filterSettingsTask: ModelSettingsTaskTypeFilterInput, $filterUser: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listSettingsMandateTypes(filter: $filterSettingsMandate, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        id
        retired
        updatedAt
        name
        cost
        translations {
          locale
          text
        }
        tasks
        order
      }
      nextToken
      startedAt
    },
    listSettingsTaskTypes(filter: $filterSettingsTask, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        id
        retired
        updatedAt
        name
        translations {
          locale
          text
        }
        order
      }
      nextToken
      startedAt
    },
    listUsers(filter: $filterUser, limit: $limit, nextToken: $nextToken) {
      items {
        _deleted
        _version
        createdAt
        email
        id
        userStatus
        updatedAt
        tenantId
        cognitoId
        username
      }
      nextToken
      startedAt
  }
  }
`;