import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"

import { useAlert } from "react-alert"
import { useMutation } from "react-query"
import { withRouter } from "react-router-dom"
import { Alert, Card, CardBody, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import { isNil } from "lodash"

import { callAPI, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { updateEvaluationObject } from "graphql/mutations"
import { firstValueFrom } from "rxjs"

//i18n
import { withTranslation } from "react-i18next"
import { EvaluationObjectType } from "models"

// Component
function MandateDetailEvaluationObjectView(props) {
  const { username, mandate, /*contacts,*/ evalObjects, onAddNew, onEdit, onDelete } = props

  // Alert
  const customAlert = useAlert()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuEvalutionObject, setOpenMenuEvaluationObject] = useState(false)

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleMenuActionAddNewMandateEvaluationObjectOnClick(e) {
    e.preventDefault()
    onAddNew()
  }

  function handleMandateEvaluationObjectOnEdit(entityMandateEvaluationObject) {
    onEdit(entityMandateEvaluationObject)
  }

  async function handleMandateEvaluationObjectOnDelete(entityMandateEvaluationObject) {
    let entityMandateEvaluationObjectDelete = await doDeleteMandateEvaluationObject(entityMandateEvaluationObject)
    if (isNil(entityMandateEvaluationObjectDelete)) {
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: "Impossible de supprimer l'objet d'évaluation" }))
      return
    }
    // Success
    onDelete(entityMandateEvaluationObjectDelete)
  }

  async function doDeleteMandateEvaluationObject(entityMandateEvaluationObject) {
    const input = {
      //id: entityMandateContact.id,
      ...entityMandateEvaluationObject,
      updateUser: username,
      retired: true,
    }
    //console.log("MandateDetailEvaluationObjectView.doDeleteMandateEvaluationObject:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("MandateDetailEvaluationObjectView.doDeleteMandateContact:updateInput", updateInput)
    try {
      const result = await updateMandateEvaluationObjectMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("MandateDetailEvaluationObjectView.doDeleteMandateContact:error", error)
      return null
    }
  }

  const updateMandateEvaluationObjectMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateEvaluationObject", updateEvaluationObject, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuEvaluationObject = useCallback(() => {
    setOpenMenuEvaluationObject(!openMenuEvalutionObject)
  }, [openMenuEvalutionObject])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  const mandateEvalObjectsSortedAsc = useMemo(() => evalObjects.sort((objA, objB) => new Date(objA.createdAt).getTime() - new Date(objB.createdAt).getTime()), [evalObjects])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{props.t("Page.MandateDetail.EvaluationObjectView")}</CardTitle>
          </div>
          {!mandate.retired && (
            <Dropdown direction="left" className="ms-1" isOpen={openMenuEvalutionObject} toggle={toggleMenuEvaluationObject}>
              <DropdownToggle tag="a" className="text-muted">
                <i className="mdi mdi-dots-vertical font-size-18" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={e => alert("This action is not yet implemented") /*handleMenuActionAddNewMandateEvaluationObjectOnClick(e)*/}>{props.t("Page.MandateDetail.EvaluationObjectView.Action.AddNewObject")}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {evalObjects.length === 0 && (
          <Row className="mt-2">
            <Col xl="12">
              <Alert color="danger">
                <i className="mdi mdi-alert-outline me-1" />
                {props.t("Common.Text.Alert.Contacts")}
              </Alert>
            </Col>
          </Row>
        )}
        {evalObjects.length > 0 && (
          <Row className="mt-2">
            {mandateEvalObjectsSortedAsc.map(obj => (
              <Col sm="4" key={obj.id} className="mb-0">
                <Card>
                  <CardBody>
                    {obj.evaluationObjectType === EvaluationObjectType.BUILDING ? (
                      <React.Fragment>
                        <div className="d-flex align-items-center mb-0">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                              <i className="bx bx-building-house" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0">Building</h5>
                        </div>
                        <div className="text-muted mt-2 mb-0">
                          <p>
                            Description ...
                          </p>
                        </div>
                      </React.Fragment>
                    ) : obj.evaluationObjectType === EvaluationObjectType.LAND ? (
                      <React.Fragment>
                        <div className="d-flex align-items-center mb-0">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                              <i className="bx bx-layer" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0">Land</h5>
                        </div>
                        <div className="text-muted mt-2 mb-0">
                          <p>
                            Description ...
                          </p>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="d-flex align-items-center mb-0">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                              <i className="bx bx-question-mark" />
                            </span>
                          </div>
                          <h5 className="font-size-14 mb-0">Title</h5>
                        </div>
                        <div className="text-muted mt-2 mb-0">
                          <p>
                            Description ...
                          </p>
                        </div>
                      </React.Fragment>
                    )}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </CardBody>
    </Card>
  )
}

MandateDetailEvaluationObjectView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  username: PropTypes.string,
  mandate: PropTypes.any,
  evalObjects: PropTypes.any,
  onAddNew: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSetAsPrimary: PropTypes.func,
}

export default withRouter(withTranslation()(MandateDetailEvaluationObjectView))
