import React, { useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"

import moment from "moment"
import Moment from "react-moment"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardFooter, CardLink, CardText, CardTitle, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label, Row, Table } from "reactstrap"
import { isBoolean, isEmpty } from "lodash"

import DeleteConfirmationForm from "components/DeleteConfirmationForm/DeleteConfirmationForm"

import { getOptionById, getOptionByValue } from "helpers/lea-options_helper"
import { mandateStatusClasses } from "helpers/lea-status_helper"
import { URL_REQUESTS } from "helpers/lea-url_helper"
import { MandateGoalType, MandatePriorityType, MandatePurposeType, MandateReportType, MandateStatus } from "models"

//i18n
import { withTranslation, useTranslation } from "react-i18next"

// Component
function MandateDetailSummaryView(props) {
  const { mandate, users, optionsMandateType, onDelete, onEdit } = props

  // Detect language change
  const { i18n } = useTranslation()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  const [openMenuSummary, setOpenMenuSummary] = useState(false)
  const [openDeleteConfirmationForm, setOpenDeleteConfirmationForm] = useState(false)

  const mandateGoalOptions = useMemo(() => {return initMandateGoalOptions()}, [i18n.language])
  const mandatePaperVersionOptions = useMemo(() => {return initMandatePaperVersionOptions()}, [i18n.language])
  const mandatePurposeOptions = useMemo(() => {return initMandatePurposeOptions()}, [i18n.language])
  const mandatePriorityOptions = useMemo(() => {return initMandatePriorityOptions()}, [i18n.language])
  const mandateReportOptions = useMemo(() => {return initMandateReportOptions()}, [i18n.language])
  const mandateStatusOptions = useMemo(() => {return initMandateStatusOptions()}, [i18n.language])

  // ----------------------------------------------------------------------------------------------------------------
  // Init function (for useMemo hook)
  // ----------------------------------------------------------------------------------------------------------------
  function initMandateGoalOptions() {
    //console.log("[initGoalOptions] - params[]")
    let items = [
      { value: MandateGoalType.DAMAGE, label: props.t("Common.Enum.Goal.DAMAGE") },
      { value: MandateGoalType.INSURABLEVALUE, label: props.t("Common.Enum.Goal.INSURABLEVALUE") },
      { value: MandateGoalType.MARKETVALUE, label: props.t("Common.Enum.Goal.MARKETVALUE") },
      { value: MandateGoalType.PROSPECTIVEVALUE, label: props.t("Common.Enum.Goal.PROSPECTIVEVALUE") },
      { value: MandateGoalType.REALVALUE, label: props.t("Common.Enum.Goal.REALVALUE") },
      { value: MandateGoalType.RENTALVALUE, label: props.t("Common.Enum.Goal.RENTALVALUE") },
      { value: MandateGoalType.RETROSPECTIVEVALUE, label: props.t("Common.Enum.Goal.RETROSPECTIVEVALUE") },
      { value: MandateGoalType.SERVITUDE, label: props.t("Common.Enum.Goal.SERVITUDE") },
      { value: MandateGoalType.OTHER, label: props.t("Common.Enum.Goal.OTHER") },
    ]
    return items
  }

  function initMandatePaperVersionOptions() {
    //console.log("[initPaperVersionOptions] - params[]")
    let items = [
      { value: true, label: props.t("Common.Enum.Boolean.True") },
      { value: false, label: props.t("Common.Enum.Boolean.False") },
    ]
    return items
  }

  function initMandatePurposeOptions() {
    //console.log("[initPurposeOptions] - params[]")
    let items = [
      { value: MandatePurposeType.BUY, label: props.t("Common.Enum.Purpose.BUY") },
      { value: MandatePurposeType.CAPITALGAIN, label: props.t("Common.Enum.Purpose.CAPITALGAIN") },
      { value: MandatePurposeType.CONTESTATION, label: props.t("Common.Enum.Purpose.CONTESTATION") },
      { value: MandatePurposeType.EXPROPRIATION, label: props.t("Common.Enum.Purpose.EXPROPRIATION") },
      { value: MandatePurposeType.FINANCING, label: props.t("Common.Enum.Purpose.FINANCING") },
      { value: MandatePurposeType.FISCAL, label: props.t("Common.Enum.Purpose.FISCAL") },
      { value: MandatePurposeType.INSURANCE, label: props.t("Common.Enum.Purpose.INSURANCE") },
      { value: MandatePurposeType.INTERNALMANAGEMENT, label: props.t("Common.Enum.Purpose.INTERNALMANAGEMENT") },
      { value: MandatePurposeType.LITIGATION, label: props.t("Common.Enum.Purpose.LITIGATION") },
      { value: MandatePurposeType.MARKETRESEARCH, label: props.t("Common.Enum.Purpose.MARKETRESEARCH") },
      { value: MandatePurposeType.PENSIONFUND, label: props.t("Common.Enum.Purpose.PENSIONFUND") },
      { value: MandatePurposeType.PREEMPTIVE, label: props.t("Common.Enum.Purpose.PREEMPTIVE") },
      { value: MandatePurposeType.PROJECTPORTFOLIO, label: props.t("Common.Enum.Purpose.PROJECTPORTFOLIO") },
      { value: MandatePurposeType.REDEVELOPMENT, label: props.t("Common.Enum.Purpose.REDEVELOPMENT") },
      { value: MandatePurposeType.SALE, label: props.t("Common.Enum.Purpose.SALE") },
      { value: MandatePurposeType.SEPARATION, label: props.t("Common.Enum.Purpose.SEPARATION") },
      { value: MandatePurposeType.SUCCESSION, label: props.t("Common.Enum.Purpose.SUCCESSION") },
      { value: MandatePurposeType.OTHER, label: props.t("Common.Enum.Purpose.OTHER") },
    ]
    return items
  }

  function initMandatePriorityOptions() {
    //console.log("[initPriorityOptions] - params[]")
    let items = [
      { value: MandatePriorityType.LOW, label: props.t("Common.Enum.Priority.LOW") },
      { value: MandatePriorityType.MEDIUM, label: props.t("Common.Enum.Priority.MEDIUM") },
      { value: MandatePriorityType.HIGH, label: props.t("Common.Enum.Priority.HIGH") },
      { value: MandatePriorityType.CRITICAL, label: props.t("Common.Enum.Priority.CRITICAL") },
    ]
    return items
  }

  function initMandateReportOptions() {
    //console.log("[initReportTypeOptions] - params[]")
    let items = [
      { value: MandateReportType.ABBREVIATED, label: props.t("Common.Enum.ReportType.ABBREVIATED") },
      { value: MandateReportType.NARRATIVE, label: props.t("Common.Enum.ReportType.NARRATIVE") },
      { value: MandateReportType.CONSULTATION, label: props.t("Common.Enum.ReportType.CONSULTATION") },
      { value: MandateReportType.LETTRE, label: props.t("Common.Enum.ReportType.LETTRE") },
      { value: MandateReportType.MONITORINGWORKS, label: props.t("Common.Enum.ReportType.MONITORINGWORKS") },
    ]
    return items
  }

  function initMandateStatusOptions() {
    //console.log("[initMandateStatusOptions] - params[]")
    let items = [
      { value: MandateStatus.PENDING, label: props.t("Common.Enum.MandateStatus.PENDING") },
      { value: MandateStatus.INPROGRESS, label: props.t("Common.Enum.MandateStatus.INPROGRESS") },
      { value: MandateStatus.COMPLETED, label: props.t("Common.Enum.MandateStatus.COMPLETED") },
    ]
    return items
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleActionEditOnClick(e, mandate) {
    //console.log("[handleActionEditOnClick] - params[mandate]", mandate)
    e.preventDefault()
    onEdit(mandate)
  }

  function handleActionDeleteOnClick(e, mandate) {
    //console.log("[handleActionDeleteOnClick] - params[mandate]", mandate)
    e.preventDefault()
    toggleDeleteConfirmationForm() // Open ConfirmationForm
  }

  function handleDeleteConfirmationFormOnSave(confirmationData) {
    //console.log("[handleDeleteConfirmationFormOnSave] - params[confirmationData]", confirmationData)
    //e.preventDefault();
    toggleDeleteConfirmationForm() // Hide the form
    onDelete(mandate)
  }

  function handleViewRequest(e, requestId) {
    //console.log("[handleViewRequest] - params[requestId]", requestId)
    e.preventDefault()
    props.history.push(URL_REQUESTS + "/" + requestId)
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleMenuSummary = useCallback(() => {
    setOpenMenuSummary(!openMenuSummary)
  }, [openMenuSummary])

  const toggleDeleteConfirmationForm = useCallback(() => {
    setOpenDeleteConfirmationForm(!openDeleteConfirmationForm)
  }, [openDeleteConfirmationForm])

  // ----------------------------------------------------------------------------------------------------------------
  // Utilities
  // ----------------------------------------------------------------------------------------------------------------
  function creatorDisplayName() {
    //console.log("[creatorDisplayName] - params[]")
    let usr = users.find(item => {
      return item.cognitoId === mandate.creator
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.email
    }
    return usr.username
  }

  function assigneeDisplayName() {
    //console.log("[assigneeDisplayName] - params[]")
    let usr = users.find(item => {
      return item.cognitoId === mandate.assignee
    })
    if (isEmpty(usr)) {
      return ""
    }
    if (isEmpty(usr.username)) {
      return usr.email
    }
    return usr.username
  }

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering view
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle>{props.t("Page.MandateDetail.SummaryView")}</CardTitle>
          </div>
          {!mandate.retired && (
            <Dropdown direction="left" className="ms-1" isOpen={openMenuSummary} toggle={toggleMenuSummary}>
              <DropdownToggle tag="a" className="text-muted">
                <i className="mdi mdi-dots-vertical font-size-18" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={e => handleActionEditOnClick(e, mandate)}>{props.t("Page.MandateDetail.SummaryView.Action.Edit")}</DropdownItem>
                <DropdownItem onClick={e => handleActionDeleteOnClick(e, mandate)}>{props.t("Page.MandateDetail.SummaryView.Action.Delete")}</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {openDeleteConfirmationForm && <DeleteConfirmationForm id={mandate.id} toggle={toggleDeleteConfirmationForm} onSave={handleDeleteConfirmationFormOnSave} title={props.t("Page.MandateDetail.SummaryView.Form.DeleteConfirmation.Title")} body={props.t("Page.MandateDetail.SummaryView.Form.DeleteConfirmation.Body", { 0: getOptionById(optionsMandateType, mandate.mandateTypeId).name })} />}
        <Row className="mt-2">
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-file me-1 text-primary" />
              {props.t("Common.Label.MandateType")}
            </Label>
            <div>{getOptionById(optionsMandateType, mandate.mandateTypeId).name}</div>
          </Col>
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-target-lock me-1 text-primary" />
              {props.t("Common.Label.Goal")}
            </Label>
            <div>{isEmpty(mandate.goal) ? "-" : getOptionByValue(mandateGoalOptions, mandate.goal).label}</div>
          </Col>
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-analyse me-1 text-primary" />
              {props.t("Common.Label.PurposeOf")}
            </Label>
            <div>{isEmpty(mandate.purpose) ? "-" : getOptionByValue(mandatePurposeOptions, mandate.purpose).label}</div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-pen me-1 text-primary" />
              {props.t("Common.Label.ReportType")}
            </Label>
            <div>{isEmpty(mandate.reportType) ? "-" : getOptionByValue(mandateReportOptions, mandate.reportType).label}</div>
          </Col>
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-paper-plane me-1 text-primary" />
              {props.t("Common.Label.PaperVersion")}
            </Label>
            <div>{!isBoolean(mandate?.paperVersion) ? "-" : getOptionByValue(mandatePaperVersionOptions, mandate.paperVersion).label}</div>
          </Col>
          <Col xl="4" xs="4"></Col>
        </Row>
        <hr />
        <Row>
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-timer me-1 text-primary" />
              {props.t("Common.Label.Priority")}
            </Label>
            <div>{isEmpty(mandate.priority) ? "-" : getOptionByValue(mandatePriorityOptions, mandate.priority).label}</div>
          </Col>
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-calendar-event me-1 text-primary" />
              {props.t("Common.Label.Date.EstimedDeliveryDate")}
            </Label>
            <div>{isEmpty(mandate.estimedDeliveryDate) ? "-" : <Moment format="YYYY-MM-DD">{mandate.estimedDeliveryDate}</Moment>}</div>
          </Col>
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-calendar-check me-1 text-primary" />
              {props.t("Common.Label.Date.DeliveryDate")}
            </Label>
            <div>{isEmpty(mandate.deliveryDate) ? "-" : <Moment format="YYYY-MM-DD">{mandate.deliveryDate}</Moment>}</div>
          </Col>
          <Col xl="4" xs="4"></Col>
        </Row>
        <hr />
        <Row >
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-user-check me-1 text-primary" />
              {props.t("Common.Label.Assignee")}
            </Label>
            <div>{isEmpty(mandate.assignee) ? props.t("Common.Text.User.Unassigned") : assigneeDisplayName()}</div>
          </Col>
          <Col xl="4" xs="4">
            <Label>
              <i className="bx bx-award me-1 text-primary" />
              {props.t("Common.Label.Status")}
            </Label>
            <div>
              <span className={"badge rounded-pill badge-soft-" + mandateStatusClasses[mandate.status]}>
                {mandate.retired && <s>{getOptionByValue(mandateStatusOptions, mandate.status).label}</s>}
                {!mandate.retired && getOptionByValue(mandateStatusOptions, mandate.status).label}
              </span>
              {mandate.retired && <span className={"badge rounded-pill badge-soft-" + mandateStatusClasses["RETIRED"]}>{props.t("Common.Enum.RequestStatus.RETIRED")}</span>}
            </div>
          </Col>
          <Col xl="4" xs="4">
            {/* <Label>
              <i className="bx bx-user me-1 text-primary" />
              {props.t("Common.Label.Creator")}
            </Label>
            <div>{creatorDisplayName()}</div> */}
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="no-print">
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardLink onClick={e => handleViewRequest(e, mandate.requestId)}>{props.t("Common.Action.BackToRequest")}</CardLink>
          </div>
          <CardText className="text-end">
            {/* <small className="text-muted">
              {props.t("Common.Label.Date.CreatedAt")} : &nbsp;
              {isEmpty(mandate.createdAt) ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{mandate.createdAt}</Moment>}
            </small> */}
            <small className="text-muted">
              {props.t("Common.Text.Date.CreatedBy", { 0: moment(mandate.createdAt).format("YYYY-MM-DD"), 1: moment(mandate.createdAt).format('HH:mm:ss'), 2: creatorDisplayName()})}
            </small>
          </CardText>
        </div>
      </CardFooter>
    </Card>
  )
}

MandateDetailSummaryView.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  mandate: PropTypes.any,
  users: PropTypes.any,
  optionsMandateType: PropTypes.any,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default withRouter(withTranslation()(MandateDetailSummaryView))
