import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import { useAlert } from "react-alert"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useParams, withRouter } from "react-router-dom"
import { Col, Container, Row, Spinner } from "reactstrap"
import { includes, isEmpty, isNil } from "lodash"

import RequestDetailSummaryView from "./views/summary/RequestDetailSummaryView"
import RequestDetailSummaryViewEdit from "./views/summary/RequestDetailSummaryViewEdit"
import RequestDetailContactView from "./views/contact/RequestDetailContactView"
import RequestDetailContactViewEdit from "./views/contact/RequestDetailContactViewEdit"
import RequestDetailMandateView from "./views/mandate/RequestDetailMandateView"
import RequestDetailInvoiceView from "./views/invoice/RequestDetailInvoiceView"
import ContactSearchView from "components/ContactSearch/ContactSearchView"
import Row404 from "components/Page404/Row404"

import { useCurrentUser } from "contexts/current-user-context"
import { addBusinessDays } from "helpers/lea-date_helper"
import { getOptionById } from "helpers/lea-options_helper"
import { URL_INVOICES, URL_MANDATES, URL_REQUESTS } from "helpers/lea-url_helper"
import { archiveMandate } from "services/MandataService"
import { archiveRequest } from "services/RequestService"

import { callAPI, callAPI2, removeGeneratedProperties } from "helpers/lea-graphql_helper"
import { createMandate, createInvoice, createTask, createRequestContact, updateRequest } from "graphql/mutations"
import { getRequestDetail, listRequestDetailSettings } from "./graphql/queries"
import { ContactRole, InvoiceStatus, MandateStatus, TaskStatus } from "models"
import { firstValueFrom } from "rxjs"

// i18n
import { withTranslation } from "react-i18next"

// Component
function RequestDetailPage(props) {
  // Get request id from the current URL (query param)
  const { requestId } = useParams()

  // Alert
  const customAlert = useAlert()

  // Access the client
  const queryClient = useQueryClient()

  // Current user
  //const { currentUser: { attributes: { email } } } = useCurrentUser()
  const {
    currentUser: { username },
  } = useCurrentUser()

  // ----------------------------------------------------------------------------------------------------------------
  // State
  // ----------------------------------------------------------------------------------------------------------------
  //const [addBusinessDaysForRequest, setAddBusinessDaysForRequest] = useState(15);
  //const [addBusinessDaysForInvoice, setAddBusinessDaysForInvoice] = useState(addBusinessDaysForRequest + 30);
  const [request, setRequest] = useState({})
  const [mandates, setMandates] = useState([])
  const [invoices, setInvoices] = useState([])
  const [requestContacts, setRequestContacts] = useState([])
  const [users, setUsers] = useState([])
  const [optionsMandateTypes, setOptionsMandateTypes] = useState([])
  const [optionsTaskTypes, setOptionsTaskTypes] = useState([])
  const [companyInformation, setCompanyInformation] = useState({})

  const [openEditRequestView, setOpenEditRequestView] = useState(false)
  const [openEditContactView, setOpenEditContactView] = useState(false)
  const [openSearchContactView, setOpenSearchContactView] = useState(false)

  const [requestContactToEdit, setRequestContactToEdit] = useState({})

  // ----------------------------------------------------------------------------------------------------------------
  // Queries
  // ----------------------------------------------------------------------------------------------------------------
  // Get request details
  const {
    isError: isErrorRequestDetail,
    isIdle: isIdleRequestDetail,
    isLoading: isLoadingRequestDetail,
    isFetching: isFetchingRequestDetail,
    error: errorRequestDetail,
  } = useQuery(
    "getRequestDetail-" + requestId,
    () =>
      firstValueFrom(
        callAPI2("getRequestDetail", getRequestDetail, {
          id: requestId,
          filterMandate: { requestId: { eq: requestId } },
          filterRequestContact: {
            requestId: { eq: requestId },
            retired: { ne: "true" },
          },
          filterInvoice: { requestId: { eq: requestId } },
          limit: 1000,
        })
      ),
    {
      retry: false,
      onSuccess: result => {
        //console.log("RequestDetailPage.getRequestDetail.onSuccess:result", result)
        setRequest(isEmpty(result.getRequest) ? {} : result.getRequest)
        setRequestContacts(isEmpty(result.listRequestContacts?.items) ? [] : result.listRequestContacts?.items)
        setInvoices(isEmpty(result.listInvoices?.items) ? [] : result.listInvoices?.items)
        setMandates(isEmpty(result.listMandates?.items) ? [] : result.listMandates?.items)
      },
      onError: error => {
        console.error("RequestDetailPage.getRequestDetail.onError:error", error)
        customAlert.error(
          props.t("Common.Text.Alert.Error", {
            0: error.errors[0].message,
          }),
          { timeout: 0 }
        )
      },
    }
  )

  // Get settings data
  const {
    isError: isErrorListRequestDetailSettings,
    isIdle: isIdleListRequestDetailSettings,
    isLoading: isLoadingListRequestDetailSettings,
    isFetching: isFetchingListRequestDetailSettings,
    error: errorListRequestDetailSettings,
  } = useQuery(
    "listRequestDetailSettings-" + requestId,
    () =>
      firstValueFrom(
        callAPI2("listRequestDetailSettings", listRequestDetailSettings, {
          id: requestId,
          /* EXAMPLE
            filterSettingsCompany: { xxx: { eq: ??? } },
            filterSettingsMandate: { xxx: { eq: ??? } },
            filterSettingsTask: { xxx: { eq: ??? } },
            filterUser : { userStatus: { eq: UserStatus.ACTIVE } },
            */
          limit: 1000,
        })
      ),
    {
      retry: false,
      enabled: !isIdleRequestDetail && !isLoadingRequestDetail && !isFetchingRequestDetail,
      onSuccess: result => {
        //console.log("RequestDetailPage.listRequestDetailSettings.onSuccess:result", result)
        setCompanyInformation(isEmpty(result?.listSettingsCompanyInformations?.items) ? {} : result?.listSettingsCompanyInformations?.items[0])
        setOptionsMandateTypes(isEmpty(result?.listSettingsMandateTypes?.items) ? [] : result?.listSettingsMandateTypes?.items)
        setOptionsTaskTypes(isEmpty(result?.listSettingsTaskTypes?.items) ? [] : result?.listSettingsTaskTypes?.items)
        // Cette liste est nécessaire pour l'assignation du responsable
        let optionsUsers = []
        if (!isEmpty(result?.listUsers?.items)) {
          result?.listUsers?.items.map(row =>
            optionsUsers.push({
              cognitoId: row.cognitoId,
              value: row.cognitoId,
              label: isEmpty(row.username) ? row.email : row.username,
              email: row.email,
              disabled: row.retired,
              username: row.username,
            })
          )
        }
        setUsers(optionsUsers)
      },
      onError: error => {
        console.error("RequestDetailPage.listRequestDetailSettings.onError:error", error)
        customAlert.error(
          props.t("Common.Text.Alert.Error", {
            0: error.errors[0].message,
          }),
          { timeout: 0 }
        )
      },
    }
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Handlers
  // ----------------------------------------------------------------------------------------------------------------
  function handleRequestViewOnDelete(entityRequest) {
    //console.log("RequestDetailPage.handleRequestViewOnDelete:entityRequest", entityRequest);
    doDeleteRequest(entityRequest)
  }

  function handleRequestViewOnEdit() {
    //console.log("RequestDetailPage.handleRequestViewOnEdit");
    toggleEditRequestView() // Open EditRequestView
  }

  function handleEditRequestViewOnCancel() {
    //console.log("RequestDetailPage.handleEditRequestViewOnCancel");
    toggleEditRequestView() // Hide EditRequestView
  }

  function handleEditRequestViewOnSave(entityRequest) {
    //console.log("RequestDetailPage.handleEditRequestViewOnSave:entityRequest", entityRequest);
    setRequest(entityRequest)
    toggleEditRequestView() // Hide EditRequestView
  }

  function handleRequestContactViewOnAddNew() {
    //console.log("RequestDetailPage.handleContactViewOnAddNew");
    toggleSearchContactView() // Open SearchContactView
  }

  function handleRequestContactViewOnEdit(entityRequestContact) {
    //console.log("RequestDetailPage.handleContactViewOnView:entityRequestContact", entityRequestContact);
    //props.history.push(URL_CONTACTS + '/' + entityRequestContact.contactId);
    setRequestContactToEdit(entityRequestContact)
    toggleEditContactView() // Open EditContactView
  }

  async function handleRequestContactViewOnDelete(entityRequestContactDelete) {
    //console.log("RequestDetailPage.handleContactViewOnDelete:entityRequestContactDelete", entityRequestContactDelete);
    // If the contact that remove is the primary contact on request
    if (entityRequestContactDelete.primary === true) {
      request.primaryContact = null
      let requestUpdate = await doUpdateRequest(request)
      if (isNil(requestUpdate)) {
        customAlert.error(
          props.t("Common.Text.Alert.Error", {
            0: "Impossible de modifier les infos du contact principal de la demande",
          })
        )
        return
      }
    }
    if (!isNil(entityRequestContactDelete)) {
      // Success : remove to view
      let newArr = requestContacts.filter(item => {
        return item.id !== entityRequestContactDelete.id
      })
      setRequestContacts(newArr)
    }
  }

  async function handleRequestContactViewOnSetAsPrimary(entityRequestContact) {
    //console.log("RequestDetailPage.handleContactViewOnSetAsPrimary:entityRequestContact", entityRequestContact);
    doUpdatePrimaryContactOnRequest(entityRequestContact)
  }

  async function handleContactSearchViewOnSelect(entityContact) {
    //console.log("RequestDetailPage.handleContactSearchViewOnSelect:entityContact", entityContact);
    toggleSearchContactView() // Hide ContactSearchView

    // 1) Search contact in the list
    let find = requestContacts.filter(item => item.contactId === entityContact.id)
    if (find.length !== 0) {
      customAlert.info(props.t("Page.RequestDetail.ContactView.Alert.AlreadyExists"))
      return
    }
    // 2) Create an association between request and contact (because relationship is N...N)
    let entityRequestContactNew = await doCreateRequestContact(entityContact)
    if (isNil(entityRequestContactNew)) {
      customAlert.error(
        props.t("Common.Text.Alert.Error", {
          0: "Impossible d'associer le contact à la requête",
        }),
        { timeout: 0 }
      )
      return
    }
    // Success
    setRequestContacts(prevArray => [...prevArray, entityRequestContactNew])
  }

  async function handleContactSearchViewOnAddNew(entityContactNew) {
    //console.log("RequestDetailPage.handleContactSearchViewOnAddNew:entityContactNew", entityContactNew);
    toggleSearchContactView() // Hide ContactSearchView

    // Create an association between request and contact (because relationship is N...N)
    let entityRequestContactNew = await doCreateRequestContact(entityContactNew)
    if (isNil(entityRequestContactNew)) {
      customAlert.error(
        props.t("Common.Text.Alert.Error", {
          0: "Impossible d'associer le contact à la requête",
        }),
        { timeout: 0 }
      )
      return
    }
    // Success
    setRequestContacts(prevArray => [...prevArray, entityRequestContactNew])
  }

  function handleContactSearchViewOnCancel() {
    //console.log("RequestDetailPage.handleContactSearchViewOnCancel");
    toggleSearchContactView() // Hide ContactSearchView
  }

  function handleEditRequestContactViewOnSave(entityRequestContact) {
    //console.log("RequestDetailPage.handleContactViewOnSave:entityRequestContact", entityRequestContact);
    let newArr = requestContacts.filter(item => {
      return item.id !== entityRequestContact.id
    })
    newArr.push(entityRequestContact)
    setRequestContacts(newArr)
    if (entityRequestContact.primary) {
      doUpdatePrimaryContactOnRequest(entityRequestContact)
    }
    toggleEditContactView() // Close EditContactView
  }

  function handleEditRequestContactViewOnCancel() {
    //console.log("RequestDetailPage.handleContactViewOnCancel");
    toggleEditContactView() // Close EditContactView
  }

  function handleInvoiceViewOnAddNew(e) {
    //console.log("RequestDetailPage.handleInvoiceViewOnAddNew:e", e);
    e.preventDefault()
    doCreateInvoice()
  }

  function handleMandateViewOnAddNew(settingsMandateTypeData) {
    //console.log("RequestDetailPage.handleMandateViewOnAddNew:settingsMandateTypeData", settingsMandateTypeData);
    doCreateMandate(settingsMandateTypeData)
  }

  function handleMandateViewOnDelete(entityMandate) {
    //console.log("RequestDetailPage.handleMandateViewOnDelete:entityMandate", entityMandate);
    doDeleteMandate(entityMandate)
  }

  async function doUpdatePrimaryContactOnRequest(entityRequestContact) {
    //console.log("RequestDetailPage.doUpdatePrimaryContactOnRequest:entityRequestContact", entityRequestContact);
    // Update primary contact on Request
    let primaryContact = {
      id: entityRequestContact.contactId,
      // contactType :
      address: isEmpty(entityRequestContact.contact?.address) ? null : entityRequestContact.contact?.address,
      city: isEmpty(entityRequestContact.contact?.city) ? null : entityRequestContact.contact?.city,
      email: isEmpty(entityRequestContact.contact?.email) ? null : entityRequestContact.contact?.email,
      fullName: isEmpty(entityRequestContact.contact?.fullName) ? null : entityRequestContact.contact?.fullName,
      phoneNumber: isEmpty(entityRequestContact.contact?.phoneNumber) ? null : entityRequestContact.contact?.phoneNumber,
      phoneExtension: isEmpty(entityRequestContact.contact?.phoneExtension) ? null : entityRequestContact.contact?.phoneExtension,
      phoneNumber2: isEmpty(entityRequestContact.contact?.phoneNumber2) ? null : entityRequestContact.contact?.phoneNumber2,
      phoneExtension2: isEmpty(entityRequestContact.contact?.phoneExtension2) ? null : entityRequestContact.contact?.phoneExtension2,
      stateOrProvince: isEmpty(entityRequestContact.contact?.stateOrProvince) ? null : entityRequestContact.contact?.stateOrProvince,
      zipOrPostalCode: isEmpty(entityRequestContact.contact?.zipOrPostalCode) ? null : entityRequestContact?.zipOrPostalCode,
    }
    request.primaryContact = { ...primaryContact }
    let entityRequestUpdate = await doUpdateRequest(request)
    if (isNil(entityRequestUpdate)) {
      customAlert.error(
        props.t("Common.Text.Alert.Error", {
          0: "Impossible de mettre les infos du contact principal",
        })
      )
      return
    }
    // Mettre à jour le state pour que la demande soit à jour avec la dernière version de l'objet qui a été mis à jour
    setRequest(entityRequestUpdate)
  }

  async function doDeleteRequest(entityRequest) {
    //console.log("RequestDetailPage.doDeleteRequest:entityRequest", entityRequest);
    try {
      // Call async function to archive request, mandates and tasks
      await archiveRequest(entityRequest)
      // TODO (dette technique) Je dois mettre un timeout de 500ms sinon je vois la request dans l'écran requestMaster !!!
      setTimeout(() => {
        props.history.push(URL_REQUESTS)
      }, 500)
    } catch (error) {
      console.error("RequestDetailPage.doDeleteRequest:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error }))
    } finally {
    }
  }

  async function doCreateRequestContact(entityContact) {
    //console.log("RequestDetailPage.doCreateRequestContact:entityContact", entityContact);
    const input = {
      creator: username,
      contactId: entityContact.id,
      requestId: request.id,
      contact: {
        id: entityContact.id,
        //contactType:
        fullName: entityContact.fullName,
        email: entityContact.email,
        phoneNumber: entityContact.phoneNumber,
        phoneExtension: entityContact.phoneExtension,
        phoneNumber2: entityContact.phoneNumber2,
        phoneExtension2: entityContact.phoneExtension2,
        address: entityContact.address,
        city: entityContact.city,
        stateOrProvince: entityContact.stateOrProvince,
        zipOrPostalCode: entityContact.zipOrPostalCode,
      },
      primary: false,
      retired: false,
    }
    //console.log("RequestDetailPage.doCreateRequestContact:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("RequestDetailPage.doCreateRequestContact:updateInput", updateInput)
    try {
      const result = await createRequestContactMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("RequestDetailPage.doCreateRequestContact:error", error)
      return null
    }
  }

  const createRequestContactMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createRequestContact", createRequestContact, {
        input: {
          ...data,
        },
      })
    )
  )

  async function doDeleteMandate(entityMandate) {
    //console.log("RequestDetailPage.doDeleteMandate:entityMandate", entityMandate);
    try {
      // Call async function to archive mandate and tasks
      await archiveMandate(entityMandate)
      let newArr = mandates.filter(item => {
        return item.id !== entityMandate.id
      })
      setMandates(newArr)
      // Invalid cache
      /* setTimeout(() => {
                queryClient.invalidateQueries('getRequestDetail-' + mandate.requestId)
            }, 1000); */
    } catch (error) {
      console.error("RequestDetailPage.doDeleteMandate:error", error)
      customAlert.error(props.t("Common.Text.Alert.Error", { 0: error }))
    } finally {
    }
  }

  function doCreateMandate(settingsMandateTypeData) {
    //console.log("RequestDetailPage.doCreateMandate:settingsMandateTypeData", settingsMandateTypeData);
    const input = {
      mandateTypeId: settingsMandateTypeData.id,
      order: settingsMandateTypeData.order,
      requestId: request.id,
      creator: username, // (UUID) de la session de l'utilisateur en cours
      status: MandateStatus.PENDING, // TODO PENDING OR INPROGRESS
      paperVersion: false,
      retired: false,
    }
    //console.log("RequestDetailPage.doCreateMandate:input", input);
    let updateInput = removeGeneratedProperties(input)
    //console.log("RequestDetailPage.doCreateMandate:input", updateInput);
    createMandateMutation.mutate(updateInput, {
      onSuccess: result => {
        //console.log("RequestDetailPage.doCreateMandate.createMandateMutation.onSuccess:result", result)
        doCreateTasks(requestId, result.id, settingsMandateTypeData)
        // Redirect
        setTimeout(() => {
          props.history.push(URL_MANDATES + "/" + result.id)
        }, 1000)
      },
      onError: error => {
        console.error("RequestDetailPage.doCreateMandate.createMandateMutation.onError:error", error)
        queryClient.invalidateQueries("getRequestDetail-" + requestId)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const createMandateMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createMandate", createMandate, {
        input: {
          ...data,
        },
      })
    )
  )

  function doCreateTasks(requestId, mandateId, settingsMandateType) {
    //console.log("RequestDetailPage.doCreateTasks", requestId, mandateId, settingsMandateType)
    //Créer les tâches associées au mandat (voir les paramètres de configuration)
    settingsMandateType.tasks.map(task => {
      let taskinput = {
        requestId: requestId,
        mandateId: mandateId,
        taskType: task, // Id de la tâche
        order: optionsTaskTypes.find(item => item.id === task).order,
        status: TaskStatus.PENDING,
        creator: username, // (UUID) de la session de l'utilisateur en cours
        retired: false,
      }
      createTaskMutation.mutate(taskinput, {
        onSuccess: result => {
          //console.log("RequestDetailPage.doCreateTasks.createTaskMutation.onSuccess:result", result)
        },
        onError: error => {
          console.error("RequestDetailPage.doCreateTasks.createTaskMutation.onError:error", error)
          customAlert.error(
            props.t("Common.Text.Alert.Error", {
              0: error.errors[0].message,
            })
          )
        },
      })
    })
  }

  const createTaskMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createTask", createTask, {
        input: {
          ...data,
        },
      })
    )
  )

  function doCreateInvoice() {
    //console.log("RequestDetailPage.doCreateInvoice");
    // Precondition
    // - un seul contact avec le rôle payeur doit exister
    // - l'adresse courriel du contact payeur doit exister
    if (requestContacts.filter(reqContact => includes(reqContact?.roles, ContactRole.BILLING)).length > 1) {
      customAlert.error(props.t("Page.RequestDetail.InvoiceView.Action.CreateInvoice.Validation.TooManyPayer"))
      return
    }
    let billingContact = requestContacts.find(reqContact => includes(reqContact?.roles, ContactRole.BILLING))
    if (isNil(billingContact)) {
      customAlert.error(props.t("Page.RequestDetail.InvoiceView.Action.CreateInvoice.Validation.NoContactHasTheRolePayer"))
      return
    }
    if (isNil(billingContact.contact?.email)) {
      customAlert.error(props.t("Page.RequestDetail.InvoiceView.Action.CreateInvoice.Validation.ThePayerDoesNotHaveEmailAdsress"))
      return
    }
    let lineItems = []
    let subtotalAmount = 0.0
    let increment = 0
    let activeMandates = mandates.filter(item => item.retired === false)
    activeMandates.map(mdt => {
      let option = getOptionById(optionsMandateTypes, mdt.mandateTypeId)
      // description = Nom du mandat + numéro de lot + adresse
      let desc = option.name
      let qte = 1
      let unitPrice = option.cost
      let amount = unitPrice * qte
      subtotalAmount += amount
      increment += 1
      let line = {
        id: increment,
        description: desc,
        quantity: qte,
        unitPrice: unitPrice,
        amount: amount,
        isTaxed: true,
        isTaxed2: true,
        mandateId: mdt.id,
      }
      lineItems.push(line)
    })

    let totalTaxAmount = subtotalAmount * (5.0 / 100)
    let totalTax2Amount = subtotalAmount * (9.975 / 100)
    let totalDiscountAmount = 0.0
    let totalAmount = subtotalAmount + totalTaxAmount + totalTax2Amount + totalDiscountAmount
    let issueDate = new Date().toISOString().split("T")[0]
    let dueDate = addBusinessDays(issueDate, 30).toISOString().split("T")[0] // TODO Default payment term
    const input = {
      billFrom: companyInformation.contact,
      billTo: billingContact.contact,
      isEstimate: false,
      //paymentMethod: request.client?.paymentMethod,
      tax: 5.0,
      tax2: 9.975,
      taxDiscount: 0.0,
      currencyCode: "CAD", // TODO Default currency code
      lines: lineItems,
      subtotalAmount: subtotalAmount,
      discountAmount: totalDiscountAmount,
      taxAmount: totalTaxAmount,
      tax2Amount: totalTax2Amount,
      totalAmount: totalAmount,
      issueDate: issueDate,
      dueDate: dueDate,
      paymentTerm: "30", // TODO Default payment term
      requestId: request.id,
      status: InvoiceStatus.DRAFT,
      creator: username, // (UUID) de la session de l'utilisateur en cours
      retired: false,
    }
    //console.log("RequestDetailPage.doCreateInvoice:input", input);
    let updateInput = removeGeneratedProperties(input)
    //console.log("RequestDetailPage.doCreateInvoice:updateInput", updateInput);
    createInvoiceMutation.mutate(updateInput, {
      onSuccess: newInvoice => {
        //console.log("RequestDetailPage.doCreateInvoice.createInvoiceMutation.onSuccess:result", result)
        props.history.push(URL_INVOICES + "/" + newInvoice.id)
      },
      onError: error => {
        console.error("RequestDetailPage.doCreateInvoice.createInvoiceMutation.onError:error", error)
        queryClient.invalidateQueries("getRequestDetail-" + requestId)
        customAlert.error(props.t("Common.Text.Alert.Error", { 0: error.errors[0].message }))
      },
    })
  }

  const createInvoiceMutation = useMutation(data =>
    firstValueFrom(
      callAPI("createInvoice", createInvoice, {
        input: {
          ...data,
        },
      })
    )
  )

  async function doUpdateRequest(entityRequest) {
    //console.log("RequestDetailPage.doUpdateRequest:entityRequest", entityRequest);
    const input = {
      ...entityRequest,
      updateUser: username,
    }
    //console.log("RequestDetailPage.doUpdateRequest:input", input)
    let updateInput = removeGeneratedProperties(input)
    //console.log("RequestDetailPage.doUpdateRequest:updateInput", updateInput)
    try {
      const result = await updateRequestMutation.mutateAsync(updateInput)
      return result
    } catch (error) {
      console.error("RequestDetailPage.doUpdateRequest:error", error)
      return null
    }
  }

  const updateRequestMutation = useMutation(data =>
    firstValueFrom(
      callAPI("updateRequest", updateRequest, {
        input: {
          ...data,
        },
      })
    )
  )

  // ----------------------------------------------------------------------------------------------------------------
  // Toggles
  // ----------------------------------------------------------------------------------------------------------------
  const toggleEditRequestView = useCallback(() => {
    setOpenEditRequestView(!openEditRequestView)
  }, [openEditRequestView])

  const toggleEditContactView = useCallback(() => {
    setOpenEditContactView(!openEditContactView)
  }, [openEditContactView])

  const toggleSearchContactView = useCallback(() => {
    setOpenSearchContactView(!openSearchContactView)
  }, [openSearchContactView])

  // ----------------------------------------------------------------------------------------------------------------
  // Rendering page
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <div className="page-content">
      <MetaTags>
        <title>Request detail</title>
      </MetaTags>
      <Container fluid={true}>
        {(isLoadingRequestDetail || isFetchingRequestDetail || isLoadingListRequestDetailSettings || isFetchingListRequestDetailSettings) && <Spinner className="ms-2" color="primary" />}
        {!isLoadingRequestDetail && !isFetchingRequestDetail && !isErrorRequestDetail && !isLoadingListRequestDetailSettings && !isFetchingListRequestDetailSettings && !isErrorListRequestDetailSettings && (
          <React.Fragment>
            {!isEmpty(request) ? (
              <React.Fragment>
                <Row>
                  {!openEditRequestView && !openEditContactView && !openSearchContactView && (
                    <React.Fragment>
                      <Col xl={6}>
                        <RequestDetailSummaryView request={request} users={users} onDelete={handleRequestViewOnDelete} onEdit={handleRequestViewOnEdit} />
                        <RequestDetailContactView username={username} request={request} contacts={requestContacts} onAddNew={handleRequestContactViewOnAddNew} onEdit={handleRequestContactViewOnEdit} onDelete={handleRequestContactViewOnDelete} onSetAsPrimary={handleRequestContactViewOnSetAsPrimary} />
                        <RequestDetailInvoiceView request={request} invoices={invoices} onAddNew={handleInvoiceViewOnAddNew} />
                      </Col>
                      <Col xl={6}>
                        <RequestDetailMandateView request={request} users={users} mandates={mandates} optionsMandateType={optionsMandateTypes} onAddNew={handleMandateViewOnAddNew} onDelete={handleMandateViewOnDelete} />
                      </Col>
                    </React.Fragment>
                  )}
                  <Col xl={12}>
                    {openEditRequestView && <RequestDetailSummaryViewEdit username={username} request={request} userslist={users} onCancel={handleEditRequestViewOnCancel} onSave={handleEditRequestViewOnSave} />}
                    {openEditContactView && <RequestDetailContactViewEdit sername={username} requestContact={requestContactToEdit} onCancel={handleEditRequestContactViewOnCancel} onSave={handleEditRequestContactViewOnSave} />}
                    {openSearchContactView && <ContactSearchView onSelect={handleContactSearchViewOnSelect} onAddNew={handleContactSearchViewOnAddNew} onCancel={handleContactSearchViewOnCancel} displayCancelButton={true} />}
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <Row404 />
            )}
          </React.Fragment>
        )}
      </Container>
    </div>
  )
}

RequestDetailPage.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  mandates: PropTypes.any,
}

export default withRouter(withTranslation()(RequestDetailPage))
